body i.pi,
body span.pi {
  font-family: eurofins-icon !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: rgb(103, 103, 103);
}

.pi.pi-times::before {
  font-family: eurofins-icon !important;
  content: "\ec35";
}

.pi.pi-search::before {
  content: "\ec22";
}

.pi.pi-angle-double-down,
.pi.pi-angle-double-up {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.pi.pi-angle-double-down::before {
  content: "\ec66";
}

.pi.pi-angle-down::before {
  content: "\ec63";
}

.pi.pi-angle-double-up::before {
  content: "\ec6e";
}

.pi.pi-angle-up::before {
  content: "\ec62";
}

.pi.pi-check::before {
  content: "\ec34";
}

.pi.pi-fw.pi-chevron-down::before,
.pi.pi-minus::before {
  content: "\ec3a";
}

.pi-circle-on::before {
  content: none;
}

.pi.pi-fw.pi-chevron-right::before,
.pi.pi-plus::before {
  content: "\ec3c";
}

.pi.pi-calendar::before {
  content: "\ea89";
}

.pi.pi-chevron-up::before {
  content: "\ec39";
}

.pi.pi-chevron-down::before {
  content: "\ec3a";
}

.pi.pi-fw.pi-chevron-right::before,
.pi.pi-chevron-right::before {
  content: "\ec3c";
}

.pi.pi-chevron-left::before {
  content: "\ec3b";
}

.pi.pi-fw.pi-chevron-down::before {
  content: "\ec3a";
}

.pi.pi-spinner::before {
  font-family: eurofins-icon !important;
  content: "\ebd3";
}

