.layout-nav-top {
  .page-header {
    padding: 0 10px;
    background: #5b6fa8;
    height: 50px;
    transition: margin 0.2s;
    margin-left: 25px;
    width: 400px;
    border-radius: 4px;
    color: $euNeutral;
    display: flex;
    -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: margin 0.2s;
    transition: margin 0.2s;

    @media print {
      display: none;
    }

    .page-header-left {
      font-size: 20px;
      align-items: center;
      margin-left: 5px;

      .page-icon {
        display: inline-flex;
        margin-right: 10px;
        font-size: 28px;
        margin-top: -4px;
        color: $euNeutral;
        text-decoration: none;
      }

      .page-title {
        font-size: 20px;
      }
    }

    .page-header-right {
      float: right;

      .header-btn.p-button-text-icon-left {
        height: 28px;
        font-size: 12px;
        padding: 5px 0.5rem 5px 1.6rem;
        ::ng-deep .p-button-icon-left {
          font-size: 12px;
        }

        ::ng-deep .p-button-text {
          font-size: 12px;
        }
      }
    }
  }
}

// left nav changes container
// css updated for nav left
// css added for global header
// end
.layout-nav-left {
  .page-header {
    padding: 0 0 24px 0;
    background: $euTransparent;
    display: block;
    z-index: 100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: margin 0.2s;
    transition: margin 0.2s;

    @media print {
      display: none;
    }

    .page-header-left {
      font-size: 20px;
      margin: auto 0;
      align-items: center;

      .page-title {
        font-size: 20px;
        font-weight: 500;
      }

      .page-icon {
        margin-right: 10px;
        font-size: 24px;
      }
    }

    .page-header-right {
      float: right;

      .header-btn.p-button-text-icon-left {
        height: 28px;
        font-size: 12px;
        padding: 5px 0.5rem 5px 1.6rem;
        ::ng-deep .p-button-icon-left {
          font-size: 12px;
        }

        ::ng-deep .p-button-text {
          font-size: 12px;
        }
      }
    }
  }
  .page-header.hide {
    padding: 0;
  }
}

.page-header,
.app-header,
.global-header {
  p-splitbutton {
    position: fixed;
    right: 24px;
    top: 0;
    z-index: 101;

    .p-splitbutton.p-buttonset {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      position: relative;

      .p-button.p-button-text-icon-left {
        margin: auto 0;
        padding: 0 8px;
        border: 0;
        background-color: $euTransparent;

        &:enabled:hover {
          background-color: $euTransparent;
          border: 0;
        }

        .p-button-icon-left {
          font-size: 20px;
          left: 8px;
          color: $euNeutral;
        }

        .p-button-text {
          margin-left: 28px;
          margin-right: 18px;
          font-size: $fontSize;
          color: $euNeutral;
        }
      }

      .p-button.p-button-icon-only {
        width: 24px;
        padding: 0;
        margin: auto 0;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        .p-button-icon-left {
          color: $euNeutral;
          padding: 8px;
          width: 100%;
          height: 100%;
          right: 0;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
        }

        .p-button-text {
          display: none;
        }
      }
    }
  }
}

.global-header {
  p-splitbutton {
    position: relative;
    right: 0;
    .p-splitbutton.p-buttonset {
      margin-top: -4px;
      .p-menu-overlay {
        right: 0 !important;
      }
      .p-button {
        min-width: auto;
      }

      .p-button.p-button-text-icon-left {
        height: 52px;
        &:hover {
          box-shadow: none;
        }
        .p-button-icon-left {
          color: $darkGray;

          &::before {
            margin: 0 0 4px -4px;
          }
        }

        .p-button-text {
          color: $darkGray;
          &::before {
            margin: 0 0 4px -4px;
          }
        }
      }

      .p-button.p-button-icon-only {
        height: 52px;
        .p-button-icon-left {
          color: $darkGray;
          &::before {
            margin: 0 0 4px -4px;
          }
        }
      }
    }
  }
}

.global-header.global-header-primary {
  background: $euPrimary !important;
  color: $euNeutral;
  p-splitbutton {
    .p-splitbutton.p-buttonset {
      .p-button.p-button-text-icon-left {
        .p-button-icon-left {
          color: $euNeutral;
        }

        .p-button-text {
          color: $euNeutral;
        }
      }

      .p-button.p-button-icon-only {
        .p-button-icon-left {
          color: $euNeutral;
        }
      }
    }
  }
}

.page-header {
  .p-dropdown {
    height: 52px;
    border: 0;
    border-radius: 0;
    background: $euTransparent;

    &:hover {
      background: $lighterGray;

      .p-dropdown-label,
      .p-dropdown-trigger {
        background: $euTransparent;
        color: $euWhite;

        .p-dropdown-trigger-icon {
          color: $euWhite;
        }
      }
    }

    .p-dropdown-label,
    .p-dropdown-trigger {
      background: $euTransparent;
      color: $euWhite;
      display: block;

      .p-dropdown-trigger-icon {
        color: $euWhite;
      }
    }

    .p-dropdown-label {
      padding: 0 40px 0 24px;
    }

    .p-dropdown-trigger {
      position: static;
      margin-left: -20px;
    }
  }
}

.layout-nav-left {
  .global-header {
    padding: 0 24px;
    background: $euNeutral;
    display: block;
    position: fixed;
    top: 0;
    left: 56px;
    right: 0;
    z-index: 100;
    height: 52px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

    @media print {
      display: none;
    }

    .global-header-left {
      font-size: 20px;
      margin: auto 0;
      align-items: center;
    }

    .global-header-right {
      font-size: 20px;
      margin: auto 0 auto auto;
      display: flex;
      align-items: center;
    }

    .p-inputgroup .p-inputgroup-addon:first-child {
      left: 0;
      font-size: 20px;
    }

    .p-inputgroup input:not(:first-child),
    .p-inputgroup .p-inputtext:not(:first-child) {
      border: 0;
      height: auto;
      font-size: 20px;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 20px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 20px;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 20px;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 20px;
      }
    }
  }
}

.layout-nav-top .page-header .divider {
  border-color: #ffffff;
  height: 24px;
}

.layout-nav-top .page-header p-dropdown {
  margin-top: -14px;
  position: relative;
  .p-dropdown {
    height: 60px;
    border: 0;
    border-radius: 0;
    position: absolute;
    width: auto;
    background: $euTransparent;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
      margin-top: 16px;
      height: 44px;
      .p-dropdown-trigger {
        top: 24% !important;
      }
    }

    &:hover {
      background: $euTransparent;

      .p-dropdown-label,
      .p-dropdown-trigger {
        background: $euTransparent;
        color: $euWhite;

        .p-dropdown-trigger-icon {
          color: $euWhite;
        }
      }
    }

    .p-dropdown-label,
    .p-dropdown-trigger {
      background: $euTransparent;
      color: $euWhite;
      display: flex;

      .p-dropdown-trigger-icon {
        color: $euWhite;
      }
    }

    .p-dropdown-label {
      padding: 0 28px 0 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 21px;
    }

    .p-dropdown-trigger {
      position: static;
      margin-left: -20px;

      .p-dropdown-trigger-icon.pi.pi-chevron-down::before {
        font-size: 12px;
      }
    }
  }
}

.layout-nav-left .page-header p-splitbutton {
  height: 52px;

  .p-splitbutton.p-buttonset {
    &:hover {
      background: $lighterGray;
    }
  }
}

.layout-nav-top .app-header p-splitbutton {
  height: 80px;

  .p-splitbutton.p-buttonset {
    &:hover {
      .p-button.p-button-text-icon-left {
        .p-button-icon-left {
          color: $euSecondary;
        }

        .p-button-text {
          color: $euSecondary;
        }
      }

      .p-button.p-button-icon-only {
        .p-button-icon-left {
          color: $euSecondary;
        }
      }
    }
  }
}

.layout-nav-left.layout-compress .app-header:hover ~ .global-header {
  margin-left: 180px;
}

.layout-nav-left.layout-compress .app-header:hover ~ .app-footer,
.layout-nav-left.layout-compress .app-header:hover ~ .page-header,
.layout-nav-left.layout-compress .app-header:hover ~ .content {
  margin-left: 240px;
}

.layout-nav-left .app-header ~ .nav-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.4); /*dim the background*/
  -webkit-transition: opacity 0.2s; /* For Safari 3.1 to 6.0 */
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.layout-nav-left.layout-overlap .app-header:hover ~ .nav-overlay {
  opacity: 1;
  z-index: 1000;
}
