/* You can add global styles to this file, and also import other style files */
app-new-result-view .p-dialog .p-dialog-content {
  position: static !important;
}

// body > div.p-menu {
//   border-radius: inherit;
//   padding: .8em !important;
//   width: 16em !important;
//   margin-right: .4em !important;
// }

div > app-kebab-menu > span > a > i {
  font-size: 20px !important;
}

div > ul > li > a > span.p-menuitem-icon {
  font-size: 16px;
  margin-right: 0.45em !important;
}

table
  > tbody
  > tr
  > td:nth-child(9)
  > app-kebab-menu
  > span
  > a
  > i.ei-kebab-more {
  font-size: 15px !important;
  padding-left: 1em !important;
}

table > tbody > tr > td:nth-child(6) > app-kebab-menu > span > a > i {
  font-size: 15px !important;
}

a > span.p-menuitem-icon.ei.ei-fw.ei-trash2 {
  color: red !important;
}

div > ul > li > a > span.p-menuitem-text {
  font-size: 12.39px !important;
}

.clearSearch {
  right: 32px;
  top: 12px;
  position: absolute !important;
}

.text-light {
  color: rgba(0, 0, 0, 0.6);
}

.text-lighter {
  color: rgba(0, 0, 0, 0.4);
}

.list-input .p-listbox-filter-container .p-inputtext {
  width: 260px !important;
}

app-resultfilter {
  .p-overlaypanel {
    margin-top: 0px !important;
    border-radius: unset !important;
  }

  .p-overlaypanel::before {
    content: none !important;
  }

  .p-overlaypanel::after {
    content: none !important;
  }
}
/*app-date-range > div > p-radiobutton {
    margin-bottom: 15px !important;
}*/

app-date-range, app-resultsfilter, app-resultgrid .advanceddownload  {
  p-radiobutton {
    .p-radiobutton {
      margin-right: 10px !important;
    }
  }
  body {
      button.p-button {
      min-height: 30px !important;
    }
  }
  p-dropdown {
    .p-dropdown{
      height: 30px !important;
      .p-dropdown-label {
        padding: 0 20px 0 12px !important;
      }
    }
  }
  textarea,
  input.p-inputtext,
  input{
      min-height: 30px !important;
  }
}

[libNgxDopClearListBox] {
  .listbox-cross-icon {
    cursor: pointer;
    left: calc(100% - 28px) !important;
  }
}

.p-toast-message-success {
  display: inline-block !important;
  min-width: 450px !important;
  min-height: 50px !important;
  width: auto !important;
  height: auto !important;
  border: 1px solid #51a351 !important;
  box-shadow: 2px 2px 2px #28a745 !important;
  padding: 15px 25px 15px 15px !important;

  .pi.pi-check {
    color: #51a351 !important;
    font-weight: bolder !important;
    font-size: 18px !important;
  }

  .p-toast-close-icon {
    top: 0.7em !important;
    right: 0.6em !important;
    display: inline-block !important;
    font-weight: bolder;
    font-size: large;
  }

  .p-toast-close-icon:hover {
    color: #000 !important;
  }

  .p-toast-close-icon::before {
    font-family: eurofins-icon !important;
    content: "\ec2a" !important;
  }
}

.p-toast-message-error {
  background-color: #fceae6 !important;
  border: 1px solid #e5725a !important;
  padding: 15px 25px 15px 15px !important;
  box-shadow: 2px 2px 2px #ea6346 !important;
  width: 450px;
  color: rgba(0, 0, 0, 0.8) !important;

  .ei-cross-circle {
    color: #ea6346 !important;
  }

  .p-toast-message-text-content {
    margin-left: 5px !important;
  }
}

.p-toast-top-right {
  top: 20px !important;
}

app-details div.order-details-grid {
  margin-top: 10%;
}

app-details
  div.sample-details-grid
  p-table
  div.ui-table-scrollable-header.ui-widget-header
  table
  thead
  tr
  th,
app-details
  div.sample-details-grid
  p-table
  div.ui-table-scrollable-body
  table
  tbody
  tr
  td {
  border-right-width: 0 !important;
  text-align: left !important;
}

.info-warning {
  background: #fef6e7;
  border: 1px solid #f5a710;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 125%;
  display: flex;
  align-items: center;
}

.info-warning .ei {
  color: #f5a710;
  font-size: 20px;
}

.p-skeleton {
  position: relative;
  overflow: hidden;
  background-color: #dee2e6;
  border-radius: 6px;
}

.p-skeleton:after {
  content: "";
  animation: p-skeleton-animation 1.2s infinite;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-100%);
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}

.p-current-year.p-selected-month-0.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(1) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-1.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(2) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-2.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(3) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-3.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(4) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-4.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(5) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-5.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(6) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-6.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(7) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-7.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(8) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-8.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(9) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-9.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(10) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-10.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(11) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.p-current-year.p-selected-month-11.p-datepicker
  .p-monthpicker
  .p-monthpicker-month:nth-child(12) {
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: initial !important;
}

.skel-row {
  .skel-col:empty {
    display: block;
    animation: loading 1.7s infinite linear;
    background: #dedfe1;
    height: 16px;
    width: 280px;
    background-image: -webkit-linear-gradient(
      90deg,
      #dedfe1 0%,
      #f2f3f5 20%,
      #dedfe1 40%,
      #dedfe1 100%
    );
    background-image: linear-gradient(
      to right,
      #dedfe1 0%,
      #f2f3f5 20%,
      #dedfe1 40%,
      #dedfe1 100%
    );
    background-repeat: no-repeat;
  }
}

.infinite-scroll tr.skel-row {
  display: table-row !important;
  &:not(:nth-last-child(-n + 5)) {
    display: none !important;
  }
}

@keyframes loading {
  0% {
    background-position: -128px;
  }

  100% {
    background-position: 256px;
  }
}
