.p-contextmenu,
.p-menu-dynamic {
  padding: 0 !important;

  .p-menuitem {
    margin: 0;
    .p-menuitem-link {
      height: 36px;
      padding: 0 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-decoration: none;

      .p-menuitem-icon {
        font-size: 16px;
        margin-right: 12px !important;
        color: rgb(103, 103, 103);
      }
    }
  }

  .p-menuitem.p-menuitem-active {
    .p-menuitem-link {
      background-color: #edf4f8;
    }
  }
}
