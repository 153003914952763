body {
  .p-badge {
    padding: 0 8px;
    border-color: $darkGray;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    color: $darkGray;
    font-size: 10px;
    display: inline-flex;
    align-items: center;
    min-height: 16px;
    line-height: 14px;
  }
  .p-badge.inverse {
    background-color: #65787D;
    color: $euWhite;
    border-color: #65787D;
  }
  .p-badge.submitted {
    border-color: #28a745;
    color: #28a745;
  }
  .p-badge.pending {
    border-color: #f5a710;
    color: #f5a710;
  }
  .p-badge._lab {
    border-color: #28a745;
    color: #28a745;
  }
  .p-badge.notsenterror_submitted {
    border-color: #28a745;
    color: #28a745;
  }
  .p-badge.negativeackreceived_submitted {
    border-color: #28a745;
    color: #28a745;
  }

  .p-badge-exclamation.notsenterror_submitted::before {
    content: "!";
    color: #e42e07;
  }
  .p-badge-exclamation.negativeackreceived_submitted::before {
    content: "!";
    color: #e42e07;
  }
  .p-badge-counter {
    border: none;
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1px;
  }
  .p-badge-counter.success {
    background-color: #28a745;
    color: #FFFFFF;
  }
  .p-badge-counter.progress {
    background-color: #f5a710;
    color: #FFFFFF;
  }
  .p-badge-counter.error {
    background-color: #e42e07;
    color: #FFFFFF;
  }
}
