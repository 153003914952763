body {
  p-splitbutton {
    display: inline-block;

    .p-splitbutton {
      min-width: auto;

      @media print {
        display: none;
      }

      .p-button {
        border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: $borderRadius;
        background-color: $euTransparent;
        height: 36px;
        padding: 0 18px;

        .p-button-label {
          padding: 0;
          margin-right: 14px;
          display: inline-block;
          color: $contentTextColor;
        }

        .p-button-icon {
          font-size: 16px;
          left: 18px;
          color: $lightGray;
        }
      }

      .p-menu-overlay {
        padding: 0;
        border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);
        border-radius: 3px;

        .p-menu-list {
          padding: 0;

          .p-menuitem {
            margin: 0;

            .p-menuitem-link {
              padding: 8px 16px;

              &:hover {
                background-color: #edf4f8;
              }

              .p-menuitem-icon {
                font-size: 16px;
                margin-right: 12px !important;
                color: rgba(0,0,0,.6);
              }

              .p-menuitem-text {
                font-size: 13px !important;
                line-height: 125%;
              }
            }
            .p-menuitem-link.p-disabled {
              opacity: 1;
              display: none;

              .p-menuitem-icon,
              .p-menuitem-text {
                opacity: 0.3;
              }
            }
          }
        }
      }

      // .p-button.p-button-text-icon-left:enabled:hover {
      //   border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
      //   border-radius: $borderRadius;
      //   background-color: $euTransparent;
      // }

      .p-button:enabled:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      .p-button.p-splitbutton-menubutton {
        width: 8px;
        background: none !important;
        color: inherit;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        padding: 18px;
        height: 8px;

        &:enabled:hover {
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        .p-button-icon {
          font-size: 10px;
          font-weight: bolder;
          color: $lightGray;
          margin: 14px;
          top: 0;
          left: 0;
        }

        .p-button-icon.pi.pi-chevron-down::before {
          content: "\ecfc";
        }

        .p-button-label {
          padding: 0;
          margin: 0;
          display: none;
        }
      }

      .p-button.p-splitbutton-menubutton.p-button-icon-only {
        height: 36px;
        width: 34px;
        padding: 0;

        .p-button-icon {
          position: absolute;
          margin: 4px;
          padding: 0;
          left: 6px;
          top: 10px;
        }
      }
    }
  }

  p-splitbutton.p-splitbutton-compressed {
    .p-splitbutton {
      min-width: auto;

      @media print {
        display: none;
      }
      .p-button {
        border: 0;
        &:hover {
          box-shadow: none;
        }
        .p-button-label {
          display: none;
        }
      }
      .p-button.p-splitbutton-menubutton.p-button-icon-only {
        .p-button-icon {
          display: none;
        }
      }
      .p-menu-overlay {
        .p-menu-list {
          .p-menuitem {
            .p-menuitem-link {
              white-space: nowrap;
              .ei-trash2.p-menuitem-icon,
              .ei-trash2.p-menuitem-icon ~ .p-menuitem-text {
                color: #e42e07;
              }
            }
          }
        }
      }
    }
  }

  p-splitbutton.p-splitbutton-compressed.p-splitbutton-left {
    .p-splitbutton {
      .p-menu-overlay {
        width: auto;
        left: auto !important;
        right: 0 !important;
      }
      .p-button:hover {
        background-color: #FFFFFF;
      }
    }
  }

  p-splitbutton.p-splitbutton-compressed.p-splitbutton-secondary {
    .p-splitbutton {
      .p-button {
        padding: 0 12px;
        border: $contentBorderWidth $contentBorderStyle $euSecondary;
        .p-button-icon {
          color: $euSecondary;
          left: 19px;
        }

        &:hover {
          background-color: #FFFFFF;
        }
      }
      .p-splitbutton-menubutton {
        border: none !important;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }

  .p-menu-overlay {
    padding: 0;
    border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);
    border-radius: 3px;
    z-index: 10 !important;

    .p-menu-list {
      padding: 0;

      .p-menuitem {
        margin: 0;

        .p-menuitem-link {
          padding: 8px 16px;

          &:hover {
            background-color: #edf4f8;
          }

          .p-menuitem-icon {
            font-size: 16px;
            margin-right: 12px !important;
            color: rgba(0,0,0,.6);
          }

          .p-menuitem-text {
            font-size: 13px !important;
            line-height: 125%;
          }
        }
        .p-menuitem-link.p-disabled {
          opacity: 1;
          display: none;

          .p-menuitem-icon,
          .p-menuitem-text {
            opacity: 0.3;
          }
        }
      }
    }
  }
}
