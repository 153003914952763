body {
  @-webkit-keyframes minusRotation {
    from {
      -webkit-transform: rotate(-90deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }

  @-webkit-keyframes plusRotation {
    from {
      -webkit-transform: rotate(90deg);
    }
    to {
      -webkit-transform: rotate(0deg);
    }
  }

  p-panel {
    display: block;
    border: 0;
  }

  .p-panel {
    margin-bottom: 12px;
    padding: 0;
    border: 1px solid $xtraLightestGray;
    background: $euNeutral;

    @media print {
      margin-bottom: 0;
    }

    .p-panel-header {
      background: $euNeutral;
      border: 0;
      color: $headerTextColor;
      font-size: 18px;
      font-weight: 400;
      padding: 24px 24px 24px 56px;
      position: relative;

      @media print {
        padding: 24px 24px 24px 36px;
      }

      .p-panel-icons {
        position: absolute;
        left: 24px;
        top: 24px;
        border: 0;
        background-color: $euTransparent;
        margin: 0;

        &:hover {
          background-color: $euTransparent;
        }

        span {
          font-size: 18px;
        }

        .pi.pi-minus {
          display: block;
          -webkit-animation: minusRotation 0.1s linear 1;
        }

        .pi.pi-plus {
          display: block;
          -webkit-animation: plusRotation 0.1s linear 1;
        }
      }

      .p-panel-title {
        vertical-align: unset;
        font-weight: 400;
        margin-right: 12px;
        font-size: 18px;
        line-height: 21px;

        @media print {
          font-size: 24px;
        }

        a {
          i.ei {
            font-size: 16px;
          }
        }
      }

      .panel-header-right {
        .panel-header-right-content {
          float: right;
        }
      }

      .p-panel-icons {
        &:focus {
          outline: 0;
        }

        @media print {
          display: none;
        }
      }
    }

    .p-toggleable-content {
      transition: all 0.4s ease-out;

      @media print {
        height: auto !important;
        opacity: 1 !important;
      }

      .p-panel-content {
        border: 0;
        color: $contentTextColor;
        font-size: $fontSize;
        font-weight: 400;
        padding: 0 24px 24px 56px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        @media print {
          padding: 24px 24px 24px 36px;
        }
      }
    }

    .p-panel-footer {
      padding: 24px;
      border-top: 1px solid $xtraLightestGray;

      &:empty {
        padding: 0;
        border: 0;
      }
    }
  }

  p-panel.p-highlight {
    background-color: $euTransparent;
    border: 0;
    .p-panel {
      border: 1px solid #ee7d11;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

      .p-panel-header {
        background: #fef6e7;
      }

      .p-toggleable-content {
        .p-panel-content {
          background: #fef6e7;
        }
      }
    }
  }

  p-panel.p-state-error-highlight {
    .p-panel {
      .p-panel-header {
        border: 2px solid #E42E07;
      }
    }
  }

  .p-panel-no-header {
    > .p-panel {
      > .p-panel-header {
        padding: 0;
        
        .p-panel-icons {
          display: none;
        }
      }

      > .p-toggleable-content {
        > .p-panel-content {
          padding: 24px;
          @media print {
            padding: 24px 0 0 0;
          }
        }
      }
    }
  }

  .p-panel-no-footer {
    > .p-panel {
      > .p-toggleable-content {
        > .p-panel-footer {
          padding: 0;
          border: 0;
        }
      }
    }
  }
}
