body {
  .hide-summary {
    .p-datatable {
      .p-datatable-footer {
        display: none;
      }
    }
  }
  .p-datatable {
    .p-datatable-loading,
    .p-datatable-loading-content {
      position: fixed;
    }

    .p-datatable-caption {
      border: none;
      padding: 0;
      width: 100%;
      margin-bottom: 16px;
      background-color: $euTransparent;
      text-align: left;
    }
    .p-datatable-wrapper {
      table {
        border-collapse: separate;
        border-spacing: 0;
        @media print {
          border-width: 0;
          border-style: solid;
          border-color: rgb(229 229 229);
          background: $euNeutral;
        }
        .p-datatable-thead {
          background-color: #d2e3ed;
          .pi,
          span.pi {
            font-family: "primeicons", sans-serif !important;
          }
          > tr {
            @media print {
              border-width: 1px;
              border-style: solid;
              border-color: rgb(229 229 229);
            }
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              background-color: #d2e3ed;
            }

            > th {
              border-width: 0;
              border-style: solid;
              border-color: rgb(189 204 213);
              background: #d2e3ed;
              padding: 12px;
              font-size: $fontSize;
              line-height: 15px;
              color: $headerTextColor;
              height: 40px;
              text-align: left;
              outline: none;

              @media print {
                background: $euTransparent;
              }

              p-tableheadercheckbox {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .pi.pi-check::before {
                  font-family: eurofins-icon, sans-serif;
                  content: "\ec34";
                }
              }

              @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                .p-column-resizer {
                  height: 40px;
                }
              }

              &:focus {
                outline: 0;
              }

              @media print {
                border-width: 1px;
              }
            }

            > th.min-width {
              width: 50px;
            }

            > th.medium-width {
              width: 110px;
            }
          }
        }
        .p-datatable-tbody {
          > tr {
            background-color: $euTransparent;
            outline: none;

            @media print {
              border-width: 0 1px 1px 1px;
              border-style: solid;
              border-color: rgb(229 229 229);
            }

            &:hover {
              background: #edf4f8;
              td {
                background: #edf4f8;
              }
            }

            > td {
              border-width: 0 0 1px 0;
              border-style: solid;
              border-color: rgb(229 229 229);
              padding: 8px 12px;
              font-size: $fontSize;
              line-height: 15px;
              height: 40px;
              vertical-align: middle;
              outline: none;
              background-color: $euNeutral;

              p-tablecheckbox {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .pi.pi-check::before {
                  font-family: eurofins-icon, sans-serif;
                  content: "\ec34";
                }
              }

              // Can be retained in future while cell editing scenario
              /*
              p-cellEditor {
                display: block;
                position: relative;

                .p-autocomplete-dd {
                  input.p-inputtext,
                  input {
                    background: $euTransparent;
                    border: $contentBorderWidth $contentBorderStyle
                      $euSecondaryLight;
                    border-width: 0 0 2px 0;
                    border-radius: 0;
                    padding: 10px 0;

                    &:hover,
                    &:enabled:hover:not(.p-state-error) {
                      border-color: $euSecondaryLight;
                    }

                    &:focus,
                    &:enabled:focus:not(.p-state-error) {
                      border-width: 0 0 2px 0;
                      border-color: $euSecondaryLight;
                      outline: none;
                      -webkit-box-shadow: none;
                      box-shadow: none;
                    }
                  }

                  .p-autocomplete-dropdown {
                    display: none;
                  }
                }
              }
              */

              .input-container {
                position: relative;
                .p-inputtext-minimal {
                  opacity: 1;
                }
                .edit-actions {
                  display: none;
                  .p-button-neutral {
                    padding: 0 8px;
                    height: 28px;
                    min-height: 28px;
                    background-color: #ffffff;
                    border: 0.5px solid rgba(0, 0, 0, 0.4);
                  }
                }
              }

              @media print {
                border-width: 1px;
              }
            }

            > td.p-editable-column {
              padding: 8px 12px;
              width: 240px;

              .p-autocomplete-button {
                position: absolute;
                width: auto;
                right: 0;

                button.p-button.p-button-secondary {
                  background-color: $euNeutral;
                  margin-right: 4px;
                }

                button.p-button.p-button-secondary:enabled:hover {
                  background-color: $euNeutral;
                }
              }
            }

            td {
              input.p-inputtext.p-inputtext-minimal,
              input.p-inputtext-minimal {
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0);

                &:hover {
                  border-width: 0 0 1px 0;
                  border-style: solid;
                  border-color: rgb(229 229 229);
                }

                &:focus {
                  border-width: 0 0 1px 0;
                  border-style: solid;
                  border-color: #0072bc;
                }
              }
            }

            > td {
              .table-fields-option {
                display: flex;
                flex-direction: row-reverse;
                .more-options {
                  display: inline-flex;
                  align-items: center;
                  a {
                    cursor: pointer;
                  }
                }
              }
            }
            > td.align-top {
              vertical-align: top;
            }
          }

          @-moz-document url-prefix() {
            > tr {
              &:hover {
                background: rgba(209, 221, 230, 0.4);
              }
            }
          }

          > tr.p-highlight {
            background: #fef6e7;

            &:hover {
              background: #edf4f8;
              td {
                background: #edf4f8;
              }
            }

            td {
              background: #fef6e7;

              &:hover {
                background: #edf4f8;
              }

              .edit-actions {
                display: none;
              }
            }
          }

          > tr {
            .show-on-hover {
              display: none;
            }
            .hide-on-hover {
              display: block;
            }
            &:hover {
              .show-on-hover {
                display: block;
              }
              .hide-on-hover {
                display: none !important;
              }
            }
          }

          > tr.no-hover {
            .show-on-hover {
              display: none !important;
            }
            .hide-on-hover {
              display: block !important;
            }
          }

          > tr.p-state-edited {
            background: #edf4f8 !important;

            &:hover {
              background: #edf4f8 !important;
            }

            td {
              background: #edf4f8 !important;

              &:hover {
                background: #edf4f8 !important;
              }

              input.p-inputtext.p-inputtext-minimal,
              input.p-inputtext-minimal {
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0);

                &:hover {
                  border-width: 0 0 1px 0;
                  border-style: solid;
                  border-color: rgb(229 229 229);
                }

                &:focus {
                  border-width: 0 0 1px 0;
                  border-style: solid;
                  border-color: #0072bc;
                }
              }

              .edit-actions {
                display: flex;
                position: absolute;
                bottom: -30px;
                right: 0;
                button {
                  min-width: auto;
                }
              }
            }
          }

          tr.p-contextmenu-selected.p-contextmenu-focused {
            background: #edf4f8;
          }
        }      
      }
      .p-sortable-column.p-highlight,
      .p-sortable-column.p-highlight:not(.p-highlight) {
        color: #ee7d11;
        .p-sortable-column-icon {
          color: #ee7d11;
        }
        p-sorticon {
          .p-sortable-column-icon {
            color: #ee7d11;
          }
        }
      }
      .p-sortable-column {
        // padding: 16px 30px 16px 12px !important;
        .p-sortable-column-icon {
          color: $lightestGray;
        }
        &:not(.p-highlight):hover {
          background-color: #d2e3ed;
          color: $headerTextColor;
          @media print {
            background-color: #ffffff;
          }
          .p-sortable-column-icon {
            color: $lightestGray;
          }
        }
        p-sorticon {
          display: inline-flex;
          width: 10px;
          margin-left: 8px;

          .p-sortable-column-icon {
            color: $lightestGray;
            font-family: eurofins-icon, sans-serif;
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            margin: auto;
            font-size: 10px;
            margin-left: -2px;
          }

          .p-sortable-column-icon.pi-sort-alt::before,
          .p-sortable-column-icon.pi-sort::before {
            content: "\ecf7";
          }
          .p-sortable-column-icon.pi-sort-amount-up-alt::before,
          .p-sortable-column-icon.pi-sort-up::before {
            content: "\ecfb";
          }
          .p-sortable-column-icon.pi-sort-amount-down::before,
          .p-sortable-column-icon.pi-sort-down::before {
            content: "\ecfc";
          }

          .p-sortable-column-icon.pi-sort-down,
          .p-sortable-column-icon.pi-sort-up {
            margin-top: -1px;
          }
        }
      }
    }
    .p-datatable-footer {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 24px;
      border: none;
      background-color: #EE7D11;
      color: #ffffff;
      font-weight: normal;

      .divider {
        border-color: #ffffff;
      }

      a.anchor {
        color: #ffffff;
      }
    }
    .p-paginator-bottom {
      border: none;
      padding: 0;
      margin-top: 12px;
      text-align: right;

      .p-paginator-element {
        padding: 6px 10px;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 3px;
        &:hover {
          border: 1px solid rgba(0, 0, 0, 0.4);
          border-radius: 3px;
        }
        &.p-highlight {
          border: 1px solid #ee7d11;
        }
        .p-paginator-icon {
          width: 8px;
          &::before {
            margin-left: -4px;
          }
        }
      }

      .p-paginator-pages {
        height: auto;

        .p-paginator-element {
          padding: 6px 10px;
          margin: 0 2px;
        }
      }
    }
  }
  .p-datatable-bordered {
    .p-datatable {
      table {
        border-collapse: separate;
        border-spacing: 0;
        border-width: 0;
        .p-datatable-thead {
          tr {
            th {
              border-width: 0 1px 1px 0;
              border-style: solid;
              border-color: rgb(189 204 213);
            }
            &:last-child {
              th {
                border-width: 0 1px 0 0;
              }
            }
          }
        }
        .p-datatable-tbody {
          tr {
            td {
              border-width: 0 1px 1px 0;
              border-style: solid;
              border-color: rgb(229 229 229);
            }
          }
        }
      }
    }
  }
  .hide-summary {
    .p-datatable {
      .p-datatable-footer {
        display: none;
      }
    }
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
    background: #edf4f8;
  }
  .p-datatable-caption-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .small-table {
    .p-datatable-header {
      .p-inputgroup .p-inputgroup-addon:first-child {
        top: 6px;
        left: 8px;
      }
      .p-inputgroup input:not(:first-child),
      .p-inputgroup .p-inputtext:not(:first-child) {
        padding: 6px 30px 6px 30px;
        height: 28px;
        min-height: 28px;
      }
    }
    .p-datatable {
      table {
        .p-datatable-thead {
          > tr {
            > th {
              &:first-child {
                padding: 8px 6px 8px 12px;
              }
              padding: 8px 6px;
              font-weight: 500;
              font-size: 13px;
              line-height: 15px;
              height: auto;
            }
          }
        }
        .p-datatable-tbody {
          > tr {
            > td {
              &:first-child {
                padding: 6px 6px 6px 12px;
              }
              padding: 6px;
              height: auto;
            }
          }
        }
      }
    }
  }
  .table-autoheight {
    .p-datatable {
      > table {
        .p-datatable-tbody {
          > tr {
            > td {
              padding: 12px;
              height: auto;
            }
          }
        }
      }
    }
  }
  table {
    thead {
      > tr {
        > th {
          height: 28px;
          text-align: left;
          padding: 0 8px;
        }
      }
    }
    tbody {
      > tr {
        > td {
          height: 28px;
          text-align: left;
          padding: 6px 8px;
          line-height: 19px;
        }
      }
    }
  }
  table.p-datatable-bordered {
    border-collapse: collapse;
    thead {
      > tr {
        > th {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: rgb(189 204 213);
          padding: 12px;
        }
      }
    }
    tbody {
      border-width: 1px;
      border-style: solid;
      border-color: rgb(229 229 229);
      > tr {
        > td {
          border-width: 1px 0 0 1px;
          border-style: solid;
          border-color: rgb(229 229 229);
          padding: 8px 12px;

          &:last-child {
            border-width: 1px 1px 0 1px;
          }
        }
        > td.has-error {
          border: 1px solid #e42e07 !important;
        }
        &:last-child {
          > td {
            border-width: 1px 0 1px 1px;
            &:last-child {
              border-width: 1px;
            }
          }
        }
      }
    }
  }
  .p-div-table {
    display: table;
    .p-div-table-heading {
      display: table-header-group;
      font-weight: bold;
      .p-div-table-row {
        display: table-row;
        .p-div-table-head {
          display: table-cell;
          height: 28px;
          text-align: left;
          padding: 0 8px;
          color: rgba(0, 0, 0, 0.6);
          &:empty {
            padding: 0 !important;
            width: 0;
          }
        }
      }
    }
    .p-div-table-body {
      display: table-row-group;
      .p-div-table-row {
        display: table-row;
        .p-div-table-cell {
          display: table-cell;
          height: 28px;
          text-align: left;
          padding: 6px 8px;
          vertical-align: top;
          &:empty {
            padding: 0 !important;
            width: 0;
          }
        }
        .p-div-table-cell.text-right {
          text-align: right;
        }
        .p-div-table-cell.action-cell {
          vertical-align: top;
          padding: 8px 0 12px 12px !important;
        }
      }
    }
    .p-div-table-foot {
      display: table-footer-group;
      font-weight: bold;
    }
  }
  .p-div-table.p-datatable-bordered {
    .p-div-table-heading {
      .p-div-table-row {
        .p-div-table-head {
          border-width: 1px 1px 0 1px;
          border-style: solid;
          border-color: rgb(189 204 213);
          padding: 12px;
        }
      }
    }
    .p-div-table-body {
      border-width: 1px;
      border-style: solid;
      border-color: rgb(229 229 229);
      .p-div-table-row {
        .p-div-table-cell {
          border-width: 1px 0 0 1px;
          border-style: solid;
          border-color: rgb(229 229 229);
          padding: 8px 12px;

          &:last-child {
            border-width: 1px 1px 0 1px;
          }
        }
        > .p-div-table-row.has-error {
          border: 1px solid #e42e07 !important;
        }
        &:last-child {
          > .p-div-table-cell {
            border-width: 1px 0 1px 1px;
            &:last-child {
              border-width: 1px;
            }
          }
        }
      }
    }
    .p-div-table-foot {
      border-width: 1px;
      border-style: solid;
      border-color: rgb(229 229 229);
    }
  }
  .no-hover .p-datatable table .p-datatable-tbody > tr:hover,
  .no-hover
    .p-datatable
    .p-datatable-wrapper
    table
    .p-datatable-tbody
    > tr:hover {
    background: $euTransparent;
  }
}
