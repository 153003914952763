body {
  .p-toggle {
    position: relative;
    width: 52px;
    padding: 24px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: width 0.2s;
    &.p-toggle-active {
      width: 240px;
      padding: 24px 32px 24px 24px;
      .p-toggle-header {
        .p-toggle-trigger {
          .ei-chevron-right {
            display: none;
          }
          .ei-chevron-left {
            display: block;
          }
        }
        .p-toggle-title {
          display: none;
        }
      }
      .p-toggle-content {
        visibility: visible;
        opacity: 1;
      }
    }
    .p-toggle-header {
      position: absolute;
      right: 16px;
      top: 26px;
      .p-toggle-trigger {
        font-size: 18px;
        cursor: pointer;
        color: #3f3f3f;
        .ei-chevron-right {
          display: block;
        }
        .ei-chevron-left {
          display: none;
        }
      }
      .p-toggle-title {
        display: block;
        text-transform: uppercase;
        color: rgba(0,0,0,.5);
        writing-mode: vertical-rl;
        margin-top: 16px;
      }
    }
    .p-toggle-content {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s;
      transition: opacity 0.2s;
    }
  }
}
