body {
  .p-paginator {
    .pi {
      font-family: "primeicons", sans-serif !important;
    }

    display: flex;
    border: 0;
    height: 28px;
    text-align: right;
    position: relative;
    margin: 15px 0px 10px 0px;
    background-color: $euTransparent;

    p-dropdown {
      position: absolute;
      top: 0;
      left: 160px;
      width: auto;
      margin-left: 20px;
      .p-dropdown {
        margin: 0;
        position: relative;
        min-width: 116px;
        height: 28px;
        &::before {
          position: absolute;
          top: calc(50% - 8px);
          left: -118px;
          //content: "Show";
          pointer-events: none;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          padding-left: 50px;
        }

        &::after {
          position: absolute;
          top: calc(50% - 8px);
          left: 130px;
          pointer-events: none;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          white-space: nowrap;
          padding-left: 12px;
        }

        .p-dropdown-label {
          padding: 0 36px 0 12px;
          &::after {
            //content: " per page";
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
          }
        }

        .p-dropdown-trigger {
          top: 9px;
        }

        .p-dropdown-panel
          .p-dropdown-items-wrapper
          .p-dropdown-items
          .p-dropdown-item {
          text-align: left;
          &:not([aria-label="All"]):after {
            //content: " per page";
            font-weight: 400;
          }
        }
      }
    }

    .p-paginator-current {
      cursor: text;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }

    .p-paginator-element.p-paginator-first {
      margin-left: auto;
    }

    .p-paginator-last.p-paginator-element {
      margin: 0;
    }

    .p-paginator-right-content {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      margin-left: 24px;
    }

    .p-paginator-pages .p-input-current-page,
    .p-paginator-right-content .p-input-current-page {
      width: 60px;
      height: 28px;
      min-height: 28px;
      margin: 0 4px;
    }

    .p-paginator-element {
      padding: 6px 10px;
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 3px;
      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 3px;
      }
      &.p-highlight {
        border: 1px solid #ee7d11;
      }
      .p-paginator-icon {
        width: 8px;
        &::before {
          margin-left: -4px;
        }
      }
    }

    .p-paginator-pages {
      height: 28px;

      .p-paginator-element {
        padding: 6px 10px;
      }
    }

    .p-dropdown-custom {
      width: auto;
      position: absolute;
      left: 42px;
      top: 0;
    }

    .p-dropdown-left-span {
      position: absolute;
      top: 10px;
      left: 0;
      width: max-content;
      display: block;
      text-align: left;
      -webkit-box-align: center;
      align-items: center;
      margin: auto 8px auto 0;
    }

    .p-dropdown-right-span {
      position: absolute;
      top: 10px;
      left: 120px;
      width: max-content;
      display: block;
      text-align: left;
      -webkit-box-align: center;
      align-items: center;
      margin: auto 8px auto 0;
      white-space: nowrap;
    }

    .p-dropdown-row-range {
      position: absolute;
      top: 10px;
      left: 0;
    }

    .p-input-current-page-container {
      display: flex;
      margin-left: 8px;
      align-items: center;
    }
  }
  .all-rows p-dropdown .p-dropdown .p-dropdown-label {
    &::after {
      content: "";
    }
  }
  // .p-dropdown-panel
  //   .p-dropdown-items-wrapper
  //   .p-dropdown-items
  //   .p-dropdown-item {
  //   text-align: left;
  //   &:not([aria-label="All"]):after {
  //     content: " per page";
  //     font-weight: 400;
  //   }
  // }
}
