body .p-inputswitch {
  width: 34px;
  height: 14px;
  margin-right: 12px;

  .p-inputswitch-slider {
    color: $lightestGray;
    border-radius: 30px;

    &::before {
      width: 20px;
      height: 20px;
      left: auto;
      right: 17px;
      background: #f4f4f6;
      -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
      top: -2px;
      bottom: auto;
      border-radius: 50%;
    }
  }
}

body .p-inputswitch.p-inputswitch-focus {
  .p-inputswitch-slider {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

body .p-inputswitch.p-inputswitch-checked {
  &:not(.p-disabled):hover {
    .p-inputswitch-slider {
      background: rgba(238, 125, 17, 0.5);

      &::before {
        background: #ee7d11;
      }
    }
  }

  .p-inputswitch-slider {
    background: rgba(238, 125, 17, 0.5);

    &::before {
      background: #ee7d11;
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }
}

body p-inputSwitch + .switch-text {
  margin: auto 0;
}
