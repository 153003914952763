body {
  button.p-button {
    display: inline-block;
    text-align: center;
    $euNeutral-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 16px;
    font-size: $fontSize;
    min-height: 36px;
    border-width: 1px;
    border-radius: $borderRadius;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    color: $euNeutral;
    background-color: $euSecondary;
    background-image: none;
    border-style: solid;
    border-color: $euSecondary;
    min-width: 84px;
    font-weight: normal;
    line-height: 125%;

    &:enabled:focus {
      outline: 0 none;
      outline-offset: 0px;
    }

    &:enabled:hover,
    &:enabled:focus {
      color: $euNeutral;
      background-color: $euSecondary;
      background-image: none;
      border-color: $euSecondary;
      -webkit-box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
      box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
    }

    &:enabled:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .p-button-label {
      padding: 0;
    }

    @media print {
      display: none;
    }
  }

  button.p-button.p-button-icon-only {
    min-width: auto;
  }

  button[hidden] {
    display: none;
  }

  button.p-button {
    .p-button-icon-left {
      position: absolute;
      font-size: 15px;
      left: 16px;
    }

    .p-button-icon-left ~ .p-button-label {
      margin-left: 24px;
      padding: 0;
      font-size: 13px;
      line-height: 125%;
    }
  }

  button.p-button {
    .p-button-icon-right {
      position: absolute;
      font-size: 15px;
      right: 16px;
    }

    .p-button-icon-right ~ .p-button-label {
      margin-right: 24px;
      padding: 0;
      font-size: 13px;
      line-height: 125%;
    }
  }

  button.p-button.p-button-small {
    padding: 0 12px;
    line-height: 14px;
    height: 28px;
    font-size: 12px;
  }

  button.p-button.p-button-small {
    .p-button-icon-left {
      position: absolute;
      font-size: 12px;
      left: 12px;
    }

    .p-button-icon-left ~ .p-button-label {
      margin-left: 20px;
      padding: 0;
    }
  }

  button.p-button.p-button-small {
    .p-button-icon-right {
      position: absolute;
      font-size: 12px;
      right: 12px;
    }

    .p-button-icon-right ~ .p-button-label {
      margin-right: 20px;
      padding: 0;
    }
  }

  button.p-button.p-button-large {
    padding: 0 24px;
    line-height: 19px;
    height: 48px;
    font-size: 16px;

    .p-button-label {
      font-size: 16px;
    }
  }

  button.p-button.p-button-large {
    .p-button-icon-left {
      position: absolute;
      font-size: 20px;
      left: 24px;
    }

    .p-button-icon-left ~ .p-button-label {
      margin-left: 32px;
      padding: 0;
    }
  }

  button.p-button.p-button-large {
    .p-button-icon-right {
      position: absolute;
      font-size: 20px;
      right: 24px;
    }

    .p-button-icon-right ~ .p-button-label {
      margin-right: 32px;
      padding: 0;
    }
  }

  button.p-button.p-button-secondary {
    color: $euSecondary;
    background-color: $euTransparent;
    border-color: $euSecondary;

    &:enabled:hover,
    &:enabled:focus {
      color: $euSecondary;
      background-color: $euTransparent;
      border-color: $euSecondary;
    }
  }

  button.p-button.p-button-tertiary {
    color: $lightGray;
    background-color: $euTransparent;
    border-color: $lighterGray;

    &:enabled:hover,
    &:enabled:focus {
      color: $lightGray;
      background-color: $euTransparent;
      border-color: $lightGray;
    }
  }

  button.p-button.p-button-neutral {
    color: $euNeutral;
    background-color: $euTransparent;
    border-color: $euNeutral;

    &:enabled:hover,
    &:enabled:focus {
      color: $euNeutral;
      background-color: $euTransparent;
      border-color: $euNeutral;
    }
  }

  button.p-button.mimic-dropdown,
  button.p-button.mimic-dropdown:hover,
  .mimic-dropdown button.p-button,
  .mimic-dropdown button.p-button:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(0,0,0,0.8) !important;
    border-color: rgba(0,0,0,0.2) !important;
    .p-button-icon-right {
      position: absolute;
      font-size: 8px;
      right: 12px
    }
  }

  button.p-button-empty {
    .p-button-label {
      display: none;
    }
  }
}
