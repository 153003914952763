body {
  .p-overlaypanel {
    // z-index: 11015 !important;
    border: 1px solid $lightestGray;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);

    &::before {
      border: 10px solid transparent;
      border-bottom-color: $lightestGray;
    }

    &::after {
      border: 8px solid transparent;
      border-bottom-color: $euNeutral;
    }

    .p-overlaypanel-content {
      padding: 0 16px 16px 16px;
      margin-top: 28px;
    }

    .p-overlaypanel-close {
      position: absolute;
      top: 4px;
      right: 8px;
      width: 16px;
      height: 16px;
      line-height: normal;
      border-radius: 0;
      border: none;
    }
  }

  .p-overlaypanel.p-overlaypanel-flipped {
    margin-top: -12px;

    &::before {
      border-top-color: $lightestGray;
    }

    &::after {
      border-top-color: $euNeutral;
    }
  }

  .overflow-overlay.p-overlaypanel {
    .p-overlaypanel-content {
      overflow-y: auto;
      height: 520px;
    }
  }

  // Temp fix for overlay tooltip issue in IE
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .p-overlaypanel-arrow-left {
      .p-overlaypanel:after,
      .p-overlaypanel:before {
        display: inline-block;
        left: 8px;
        right: auto;
      }

      .p-overlaypanel:before {
        left: 7.5px;
      }
    }
  }
}
