body {
  .p-steps,
  .p-steps.p-steps-readonly {
    display: flex;
    position: relative;

    ul[role="tablist"] {
      display: flex;
      flex-grow: 1;

      .p-steps-item {
        background: rgba(0, 0, 0, 0);
        border: 0;
        flex-grow: 1;
        position: relative;
        outline: none;

        &:before {
          content: " ";
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%;
          top: 26%;
          left: 0;
          display: block;
          position: absolute;
        }

        &:first-child::before {
          width: 50%;
          left: 50%;
        }

        &:last-child::before {
          width: 50%;
          left: 0;
          right: 50%;
        }

        .p-menuitem-link {
          padding: 0 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          outline: none;
          .p-steps-title {
            margin-top: 16px;
          }
          .p-steps-number {
            font-size: 16px;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            border-radius: 36px;
            border-width: 0px;
            padding: 0 13px;
          }
        }
      }
      .p-steps-item,
      .p-steps-item:hover,
      .p-steps-item.p-disabled,
      .p-steps-item:not(.p-highlight):not(.p-disabled):hover {
        opacity: 1;
        background-color: $euTransparent;
        cursor: default;
        .p-menuitem-link {
          // pointer-events: none;
          // cursor: default;
          .p-steps-number {
            background-color: rgba(0, 0, 0, 0.2);
            color: #ffffff;
            z-index: 1;
          }
        }
      }
      .p-steps-item.p-highlight,
      .p-steps-item.p-highlight:not(.p-disabled):hover,
      .p-steps-item.p-highlight.p-state-visited,
      .p-steps-item.p-highlight.p-state-visited.p-steps-current:hover {
        .p-menuitem-link {
          // pointer-events: auto;
          // cursor: pointer;
          .p-steps-number {
            background-color: #f5a710;
            color: #ffffff;
          }
          .p-steps-title {
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .p-steps-item.p-state-complete,
      .p-steps-item.p-state-complete:not(.p-disabled):hover,
      .p-steps-item.p-state-complete.p-state-visited,
      .p-steps-item.p-state-complete.p-state-visited:hover {
        .p-menuitem-link {
          // pointer-events: auto;
          // cursor: pointer;
          .p-steps-number {
            background-color: #28a745;
            color: #ffffff;
          }
        }
      }
      .p-steps-item.p-state-visited,
      .p-steps-item.p-state-visited:not(.p-disabled):hover {
        .p-menuitem-link {
          // pointer-events: auto;
          // cursor: pointer;
          .p-steps-number {
            background-color: rgb(243 197 100);
            color: #ffffff;
          }
        }
      }
      .p-steps-item.p-highlight.p-state-visited.p-steps-current,
      .p-steps-item.p-steps-disabled {
        pointer-events: none;
        cursor: default;
        .p-menuitem-link {
          pointer-events: none;
          cursor: default;
        }
      }
      .p-steps-item.p-steps-disabled .p-menuitem-link .p-steps-number {
        background-color: #c3c3c5;
      }
    }
  }
}
