body {
  .p-treetable {
    background: $euTransparent;
    table {
      table-layout: auto;
    }
    .p-treetable-caption {
      padding: 0;
      border: 0;
      margin-bottom: 12px;
      background: $euTransparent;
    }
    .p-treetable-thead {
      tr {
        th {
          border-width: 0 0 1px 0;
          border-style: solid;
          border-color: rgb(189 204 213);
          background: #d2e3ed;
          padding: 0 24px;
          font-size: $fontSize;
          line-height: 15px;
          color: $headerTextColor;
          height: 40px;
          min-width: 100px;
          text-align: left;

          &:first-child {
            padding-left: 45px;
          }
        }
      }
    }
    .p-treetable-tbody {
      tr {
        background-color: $euTransparent;
        border: 0;
        td {
          border-width: 0 0 1px 0;
          border-style: solid;
          border-color: rgba(0,0,0,0.1);
          background: $euTransparent;
          padding: 0 24px;
          font-size: $fontSize;
          line-height: 15px;
          height: 40px;
          vertical-align: middle;

          &:first-child {
            p-treetabletoggler {
              &:empty {
                margin-right: 37px;
              }
            }
          }

          .p-treetable-toggler {
            top: 2px;
            height: auto;
            margin-right: 16px;
          }
          .p-treetable-toggler {
            top: 0;
          }

          .pi.pi-minus::before {
            content: "";
            width: 6px;
            height: 6px;
            background-color: #EE7D11;
            display: block;
          }
        }
      }
    }
  }
}
