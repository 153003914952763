body {
  select.has-error {
    border-color: rgb(216, 12, 12);
    color: rgb(230, 14, 14);
  }
  select:focus {
    outline: none;
  }
  p-dropdown {
    display: block;
    width: auto;

    .p-dropdown {
      background: #F4F4F6;
      border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: $borderRadius;
      font-size: $fontSize;
      width: 100%;
      height: 36px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      border-width: 1px;
      background-repeat: no-repeat;
      cursor: pointer;
      min-width: auto;

      &:not(.p-disabled):hover {
        background: #F4F4F6;
      }

      @media print {
        display: none;
      }

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
        border-color: rgba(0, 81, 229, 0.6);
        border-width: 1px;
      }

      .p-dropdown-label {
        background: rgba(0, 0, 0, 0);
        padding: 0 40px 0 12px;
        font-size: $fontSize;
        line-height: 16px;
        text-align: left;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.8);
      }

      .p-dropdown-clear-icon {
        font-size: 8px;
        font-weight: bolder;
        right: 32px;
        color: $lightGray;
      }

      .p-dropdown-trigger {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 12px;
        right: 16px;
        border: 0;
        background-color: $euTransparent;
        margin: -2px;

        .p-dropdown-trigger-icon {
          font-size: 8px;
          font-weight: bolder;

          &.pi.pi-chevron-down::before {
            font-family: eurofins-icon, sans-serif;
            content: "\ecf9";
          }
        }
      }

      .p-dropdown-panel {
        border: 1px solid $lightestGray;
        border-radius: 0;
        background-color: #fff;

        .p-dropdown-items-wrapper {
          .p-dropdown-items {
            padding: 0;

            .p-dropdown-item {
              padding: 8px 12px;
              white-space: pre-wrap;
              border: 0;

              &:hover{
                background: #EDF4F8;
                
                .show-on-hover {
                  display: inline-block !important;
                }
              }

              &.p-highlight {
                border: 0;
              }
              
              .show-on-hover {
                display: none;
              }
            }

            .p-dropdown-item.p-dropdown-item-empty {
              padding: 0;
              height: 0;
              border-width: 2px 0 0 0;
              border-style: solid;
              border-color: rgba(0,0,0,0.1);
              margin-left: -8px;
              margin-right: -8px;
            }

            .p-dropdown-empty-message{
              color: rgb(230, 14, 14);
              margin: 8px;
            }
          }
        }

        .p-dropdown-filter-container {
          margin: 8px;
          padding: 0;

          .p-dropdown-filter.p-inputtext {
            padding: 10px 12px 10px 34px;
            border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
            border-radius: $borderRadius;

            &:hover:not(.p-state-error) {
              border-color: rgba(0, 0, 0, 0.3);
            }

            &:focus:not(.p-state-error) {
              -webkit-box-shadow: none;
              box-shadow: none;
              outline: 0;
              border-color: rgba(0, 81, 229, 0.6);
              border-width: 1px;
            }
          }

          .p-dropdown-filter-icon {
            background: none !important;
            color: $lightGray;
            border: none;
            position: absolute;
            top: 18px;
            left: 12px;
            right: auto;
            padding: 0;
            min-width: auto;
            font-size: 16px;
          }
        }
      }
    }

    .p-dropdown.p-focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
      border-color: rgba(0, 81, 229, 0.6);
      border-width: 1px;

      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
        border-color: rgba(0, 81, 229, 0.6);
        border-width: 1px;
      }
    }
  }
  .p-dropdown-panel {
    border: 1px solid $lightestGray;
    max-width: 360px;
    border-radius: 0;
    background-color: #fff;

    .p-dropdown-items-wrapper {
      .p-dropdown-items {
        padding: 0;

        .p-dropdown-item {
          padding: 8px 12px;
          white-space: pre-wrap;
          border: 0;

          &:hover{
            background: #EDF4F8;
            
            .show-on-hover {
              display: inline-block !important;
            }
          }
          
          .show-on-hover {
            display: none;
          }
        }

        .p-dropdown-item.p-dropdown-item-empty {
          padding: 0;
          height: 0;
          border-width: 2px 0 0 0;
          border-style: solid;
          border-color: rgba(0,0,0,0.1);
          margin-left: -8px;
          margin-right: -8px;
        }

        .p-dropdown-empty-message{
          color: rgb(230, 14, 14);
        }
      }
    }

    .p-dropdown-filter-container {
      margin: 8px;
      padding: 0;

      .p-dropdown-filter.p-inputtext {
        padding: 10px 12px 10px 34px;
        border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
        border-radius: $borderRadius;

        &:hover:not(.p-state-error) {
          border-color: rgba(0, 0, 0, 0.3);
        }

        &:focus:not(.p-state-error) {
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: 0;
          border-color: rgba(0, 81, 229, 0.6);
          border-width: 1px;
        }
      }

      .p-dropdown-filter-icon {
        background: none !important;
        color: $lightGray;
        border: none;
        position: absolute;
        top: 18px;
        left: 12px;
        right: auto;
        padding: 0;
        min-width: auto;
        font-size: 16px;
      }
    }
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .hide-search {
    .p-dropdown {
      .p-dropdown-panel {
        .p-dropdown-filter-container {
          display: none;
        }
      }
    }
  }

  p-dropdown.p-dropdown-small {
    min-width: 75px;
    width: fit-content;

    .p-dropdown {
      min-width: 75px;
      width: fit-content;

      .p-dropdown-label {
        padding: 0 24px 0 12px;
      }

      .p-dropdown-panel {
        border: 1px solid $lightestGray;
        background-color: #fff;

        .p-dropdown-items-wrapper {
          overflow: visible;
          max-height: 100% !important;

          .p-dropdown-items {
            padding: 0;

            .p-dropdown-item {
              padding: 2px 16px;
            }
          }
        }
      }
    }
  }
  p-dropdown.p-dropdown-minimal {
    .p-dropdown {
      border: none;
      height: 100%;
      background: $euTransparent;
      min-width: 52px;

      &:not(.p-disabled):hover {
        background: $euTransparent;
      }

      .p-dropdown-label {
        padding: 0 28px 0 0;
      }

      .p-dropdown-clear-icon {
        right: 16px;
      }

      .p-dropdown-trigger {
        top: 3px;
        right: 4px;

        .p-dropdown-trigger-icon {
          font-size: 8px;
          font-weight: bolder;
          margin: -5px;
        }
      }

    }
  }
  p-dropdown.has-error {
    .p-dropdown{
      border-color: rgb(216, 12, 12);
      color: rgb(230, 14, 14);

      &:hover {
        border-color: rgb(216, 12, 12);
        color: rgb(230, 14, 14);
      }
    }
  }
  .custom-select {
    position: relative;
    min-width: 72px;
    &::after {
      font-family: eurofins-icon, sans-serif;
      content: "\ecfc";
      position: absolute;
      top: 14px;
      right: 12px;
      font-size: 8px;
      color: rgba(0,0,0,.6);
      height: 8px;
      display: flex;
      align-items: center;
    }
    select {
      width: 100%;
    }
  }
  .custom-select-minimal::after {
    top: 7px;
  }
  select {
    border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: $borderRadius;
    font-size: $fontSize;
    height: 36px;
    padding: 0 12px;
    appearance: none;
    background-image: none;
    cursor: pointer;
  }
  select.p-dropdown-minimal {
    border: 0;
    padding: 4px 16px 4px 4px;
    min-width: 52px;
    height: auto;
    background: $euTransparent;
    width: auto !important;
  }
  select.has-error {
    border: 1px solid #e42e07 !important;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    select {
      padding: 0 0 0 12px;
    }
  }
}

.selected-on-top.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-items {
  padding-top: 40px;
  .p-dropdown-item.p-highlight {
    position: absolute;
    top: 8px;
    width: calc(100% - 16px);
  }
}

.p-dropdown-blackText
{
  color: rgba(0,0,0,0.8);
}