.layout-nav-top {
  p-menubar {
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;

    @media print {
      display: none;
    }

    .p-menubar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 80px;
      padding: 0 24px;
      border-radius: 0;
      border: 0;
      background: $euPrimary;
      color: $euNeutral;

      &::after {
        display: none;
      }

      .p-menubar-root-list {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;

        .p-menuitem {
          &:last-child {
            border-width: 0;
            border-style: solid;
            border-color: $euPrimaryLight;
          }

          &:only-child {
            border-width: 0 0 0 0;
          }

          .p-menuitem-link {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            height: 80px;
            padding: 7px 14px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            &:hover {
              background-color: $euPrimary;

              .p-menuitem-icon {
                color: $euSecondary !important;
              }

              .p-menuitem-text {
                color: $euSecondary;
              }
            }

            &:focus {
              outline: none;
            }

            .p-menuitem-text {
              text-align: left;
              color: $euNeutral;
            }

            .p-menuitem-icon {
              margin: 0;
              margin-bottom: 8px;
              color: $euNeutral !important;

              &::before {
                font-size: 24px;
              }
            }

            .p-submenu-icon {
              margin: 0;
              display: none;
              color: $euNeutral;
            }
          }

          .p-menuitem-link.p-menuitem-active,
          .p-menuitem-link.p-menuitem-link-active {
            background-color: $euPrimary;

            .p-menuitem-icon {
              color: $euSecondary !important;
            }

            .p-menuitem-text {
              color: $euSecondary;
            }
          }
        }

        .p-menuitem.p-menuitem-active {
          .p-menuitem-link {
            background-color: $euPrimary;

            .p-menuitem-icon {
              color: $euSecondary !important;
            }

            .p-menuitem-text {
              color: $euSecondary;
            }
          }
        }

        .p-menuitem:last-child {
          .p-submenu {
            .p-submenu-list {
              left: auto !important;
              right: 0;
            }
          }
        }

        .p-menu-separator {
          height: 60px;
          margin: 0.8rem 1rem;
          border: 1px solid $euPrimaryLight;
        }

        .p-submenu-list {
          border: 0;
          background-color: $euPrimaryLight;
          padding: 0;
          min-width: 200px;

          .p-menuitem {
            margin: 0;
            .p-menuitem-link {
              width: 100%;
              height: 100%;
              padding: 12px 16px;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -ms-flex-direction: row;
              flex-direction: row;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              background-color: $euPrimaryLight;

              &:hover {
                color: $euSecondary;
                background-color: $lightestGray;

                .p-menuitem-icon {
                  color: $euSecondary !important;
                }

                .p-menuitem-text {
                  color: $euSecondary;
                }
              }

              .p-menuitem-icon {
                color: $euNeutral !important;
                margin: 0 12px 0 0 !important;
                display: inline-flex;

                &::before {
                  font-size: 16px;
                }
              }

              .p-menuitem-text {
                color: $euNeutral;
              }
            }
          }
        }

        .p-submenu-list-right {
          right: 0;
        }
      }

      .p-menubar-custom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        float: left;

        .app-logo {
          margin: auto 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;

          .full-logo {
            // display: none;
            // margin: auto 0;
            cursor: pointer;
            display: inline-block;
          }

          .half-logo {
            // display: inline-block;
            // margin: auto 4px auto 0;
            display: none;
          }

          // .site-name {
          //   font-size: 16px;
          //   margin: auto 0;
          // }
        }
      }
    }
  }

  p-breadcrumb + .content {
    margin-top: 0;
  }
}

// left nav changes container
// css updated for nav left
// css added for global header
// end
.layout-nav-left {
  p-menubar {
    @media print {
      display: none;
    }

    .p-menubar {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      padding: 0;
      border-radius: 0;
      border: 0;
      background: $euPrimary;
      color: $euNeutral;

      &::after {
        display: none;
      }

      .p-menubar-root-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        &:hover {
          overflow-y: auto;
          height: calc(100vh - 52px);
        }

        /* width */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.6);
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        .p-menuitem {
          .p-menuitem-link {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            height: 56px;
            padding: 0 16px 0 12px;
            border-width: 0 0 0 4px;
            border-style: solid;
            border-color: $euTransparent;
            border-radius: 0;
            background-color: $euTransparent;
            outline: none;

            &:hover {
              background: $lightestGray;
              border-width: 0 0 0 4px;
              border-style: solid;
              border-color: $euTransparent;
              border-radius: 0;
            }

            .p-menuitem-text {
              width: 0;
              opacity: 0;
              white-space: nowrap;
              visibility: hidden;
              color: $euNeutral;
              margin: auto 0 auto 16px;
              -webkit-transition: opacity 0.4s, visibility 0.4s; /* For Safari 3.1 to 6.0 */
              -o-transition: opacity 0.4s, visibility 0.4s;
              transition: opacity 0.4s, visibility 0.4s;
              font-size: 16px;
              line-height: 16px;
            }

            .p-menuitem-icon {
              margin: auto 0 auto 0;
              color: $euNeutral !important;
              width: auto;

              &::before {
                font-size: 24px;
              }
            }

            .p-submenu-icon {
              margin: 0;
              display: none;
              color: $euNeutral;
            }
          }

          .p-menuitem-link.p-menuitem-current,
          .p-menuitem-link.p-menuitem-active {
            background-color: $lighterGray;
            border-width: 0 0 0 4px;
            border-style: solid;
            border-color: $euSecondary !important;
            border-radius: 0;

            &:focus {
              outline: none;
            }
          }
        }

        .p-menu-separator {
          height: auto;
          margin: 0.8rem 1rem;
          border: 1px solid $euPrimaryLight;
        }

        .p-submenu {
          opacity: 0;
          visibility: hidden;
          position: fixed;
          top: 0;
          left: 240px;
          width: 240px;
          -webkit-transition: opacity 0.3s, visibility 0.3s; /* For Safari 3.1 to 6.0 */
          -o-transition: opacity 0.3s, visibility 0.3s;
          transition: opacity 0.3s, visibility 0.3s;

          .p-submenu-list {
            border: 0;
            background-color: $euPrimary;
            position: relative;
            top: 0 !important;
            padding: 0;
            border-radius: 0;
            min-width: 200px;

            &:hover {
              overflow-y: auto;
            }

            /* width */
            &::-webkit-scrollbar {
              width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, 0);
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: rgba(255, 255, 255, 0.6);
              border-radius: 8px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }

            .p-menuitem {
              margin: 0;

              .p-menuitem-link {
                width: 100%;
                padding: 0 16px 0 12px;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                background-color: $lightestGray;
                border: 0;

                &:hover {
                  background-color: #0d214f;
                }

                .p-menuitem-icon {
                  color: $euNeutral !important;
                }

                .p-menuitem-text {
                  color: $euNeutral;
                  font-size: 14px;
                }
              }
            }
          }
        }

        .p-submenu-list-right {
          right: 0;
        }
      }

      .p-menubar-custom {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        float: left;
        padding: 0 15px;
        height: 52px;
        border-width: 0;
        border-style: solid;
        border-color: $euPrimaryLight;

        .app-logo {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;

          .full-logo {
            display: inline-block;
            margin: auto 0;
            width: 0;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: opacity 0.2s, visibility 0.2s; /* For Safari 3.1 to 6.0 */
            -o-transition: opacity 0.2s, visibility 0.2s;
            transition: opacity 0.2s, visibility 0.2s;
          }

          .site-name {
            width: 0;
            opacity: 0;
            white-space: nowrap;
            visibility: hidden;
            font-size: 16px;
            margin: auto auto auto 8px;
            -webkit-transition: opacity 0.2s, visibility 0.2s; /* For Safari 3.1 to 6.0 */
            -o-transition: opacity 0.2s, visibility 0.2s;
            transition: opacity 0.2s, visibility 0.2s;
          }

          .half-logo {
            width: 24px;
            opacity: 1;
            visibility: visible;
            margin: auto 0;
            -webkit-transition: opacity 0.2s, visibility 0.2s; /* For Safari 3.1 to 6.0 */
            -o-transition: opacity 0.2s, visibility 0.2s;
            transition: opacity 0.2s, visibility 0.2s;
          }
        }
      }
    }
  }

  .app-header {
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    width: 56px;
    -webkit-transition: width 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: width 0.2s;
    transition: width 0.2s;
    background: #003883;

    &:hover {
      width: 240px;

      p-menubar {
        .p-menubar {
          .p-menubar-root-list {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;

            .p-menuitem {
              .p-menuitem-link {
                -webkit-box-align: end;
                -ms-flex-align: end;
                align-items: end;

                .p-menuitem-text {
                  width: auto;
                  opacity: 1;
                  visibility: visible;
                  white-space: normal;
                }
              }
            }

            .p-submenu {
              opacity: 1;
              visibility: visible;
            }
          }
          .p-menubar-custom {
            .app-logo {
              .full-logo {
                -webkit-box-align: end;
                -ms-flex-align: end;
                align-items: end;
                opacity: 1;
                visibility: visible;
                width: 210px;
              }

              .site-name {
                width: auto;
                opacity: 1;
                visibility: visible;
              }

              .half-logo {
                display: inline-block;
                margin: auto 0;
                width: 0;
                opacity: 0;
                visibility: hidden;
              }
            }
          }
        }

        .p-menuitem.p-menuitem-active .p-menuitem-link {
          background: $lightestGray;
          border-width: 0 0 0 4px;
          border-style: solid;
          border-color: $euTransparent;
          border-radius: 0;
        }
      }
    }
  }

  p-breadcrumb + .content {
    margin: 0 0 0 60px;
    -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }

  .page-header,
  .content,
  .app-footer {
    margin: 0 0 0 60px;
    -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
  }

  .page-header {
    margin: 76px 0 0 60px;
  }

  .app-footer {
    margin: 0 0 0 60px;
  }
}

.layout-nav-left.layout-compress .app-header .app-header:hover ~ p-breadcrumb + .content {
  margin: 100px 0 0 240px;
  -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

.layout-nav-left.layout-compress .app-header .app-header:hover ~ .content,
.layout-nav-left.layout-compress .app-header .app-header:hover ~ .page-header {
  margin: 0 0 0 240px;
  -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

.content {
  @media print {
    margin: 0 !important;
  }
}
