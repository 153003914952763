body {
  .p-toast {
    // i.pi,
    // span.pi {
    //   font-family: "primeicons" !important;
    // }
    .pi-check:before {
      content: "\ec59";
    }

    .p-toast-icon-close {
      position: absolute;
      right: 16px;
    }

    .p-toast-message.p-toast-message-info {
      background: #e6f1f8;
      border: 1px solid #0072bc;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .p-toast-message-content {
        padding: 0;
      }
    }

    .p-toast-message.p-toast-message-success {
      background: #e9f6ec;
      border: 1px solid #28a745;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .p-toast-message-content {
        padding: 0;

        .p-toast-icon {
          color: #28a745 !important;
        }
      }
    }

    .p-toast-message.p-toast-message-warn {
      background: rgba(228, 46, 7, 0.8);
      border: 1px solid #e42e07;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;

      .p-toast-message-content {
        padding: 0;
      }
    }

    .p-toast-message.p-toast-message-error {
      background: rgba(228, 46, 7, 0.8);
      border: 1px solid #e42e07;
      color: #FFFFFF;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;

      .p-toast-message-content {
        padding: 0;

        .p-toast-icon {
          display: none;
        }
      }
    }

    .p-toast-message {
      padding: 0 16px;
      height: 48px;
      display: flex;
      align-items: center;

      @media print {
        display: none !important;
      }

      .p-toast-message-content {
        padding: 0;
        display: flex;
        align-items: center;

        .p-toast-icon {
          position: relative;
          font-size: 18px;
          margin-right: 12px;
          top: 0;
          right: 0;
          left: 0;
        }

        .p-toast-icon.pi-check {
          font-family: eurofins-icon, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          color: rgb(103, 103, 103);
        }

        .p-toast-close-icon {
          display: none;
        }

        .p-toast-message-text-content {
          display: flex;
          padding: 0;
          margin: 0;
        }
      }
    }

    .p-toast-summary {
      padding: 0;
      font-weight: 500;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .p-toast-top-right {
    top: 152px;
    right: 24px;
  }
}
