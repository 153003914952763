body {
  font-family: "Roboto", sans-serif !important;
  background-color: #f4f4f6;
  margin: 0;
  padding: 0 24px;

  @media print {
    // padding: 0;
    background-color: #ffffff;
  }

  .no-event {
    pointer-events: none;
  }
  // .content {
  //   padding: 0 24px;
  //   @media print {
  //     padding: 0;
  //   }
  // }

  * {
    box-sizing: border-box;

    &:disabled {
      opacity: 0.35;
      filter: Alpha(Opacity=35);
      background-image: none;
      cursor: default !important;
    }
  }

  .new-tag .ei {
    position: relative;
  }

  .new-tag > .p-menuitem-link > .ei:after {
    content: url("../../images/new-tag.png");
    position: absolute;
    right: -20px;
    top: -3px;
  }

  .ei-color,
  .text-light {
    color: rgba(0, 0, 0, 0.6);
  }

  .clearSearch {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  hr {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px 0 0 0;
    margin: 8px -24px;
  }
}

@-moz-document url-prefix() {
  body {
    font-family: arial, x-locale-body, sans-serif !important;
  }
}

body,
div,
span {
  font-size: 13px;
}

body {
  h4 {
    margin: 0 0 8px 0;
  }

  a.anchor {
    color: #0072bc;
    text-decoration: none;
    cursor: pointer;

    @media print {
      display: none;
    }
  }

  @media print {
    a.anchor.anchor-print {
      display: inline-block;
      color: rgb(63, 63, 63);
    }
  }

  a.clickable {
    cursor: pointer;
  }

  .green {
    color: #28a745 !important;
  }

  .blue {
    color: #0072bc;
  }

  .red {
    color: #e42e07 !important;
  }

  .orange {
    color: #f5a710 !important;
  }

  .no-pointer-event {
    pointer-events: none;
  }

  .hidden {
    display: none !important;
    opacity: 0;

    .p-button {
      pointer-events: none;
    }
  }

  .dissapear {
    visibility: none;
    opacity: 0;
    pointer-events: none;
  }

  [hidden] {
    display: none !important;
  }

  .flex-body {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;

    .align-left {
      margin-right: auto;
    }

    .align-right {
      margin-left: auto;
    }
  }

  .font-standard {
    font-size: 13px;
  }

  .font-italic {
    font-style: italic;
  }

  .break-space {
    white-space: break-spaces;
  }

  .no-break {
    white-space: pre;
  }

  .break-word {
    word-break: break-word;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      word-break: break-all;
    }
  }

  .break-all {
    word-break: break-all;
  }

  .kebab-more {
    height: 18px;
  }

  .ei-8 {
    font-size: 8px !important;
  }

  .ei-10 {
    font-size: 10px !important;
  }

  .ei-12 {
    font-size: 12px !important;
  }

  .ei-16 {
    font-size: 16px;
  }

  .ei-18 {
    font-size: 18px;
  }

  .ei-48 {
    font-size: 48px;
  }

  .no-text-decoration {
    text-decoration: none;
  }

  .text-decoration {
    text-decoration: underline;
  }

  .flex-inline-body {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .align-center {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .align-items-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }

  .justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .text-center {
    text-align: center;
  }

  .text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .line-clamp {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .line-clamp-2 {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .vertical-center {
    margin: auto 0 !important;
  }

  .vertical-top {
    vertical-align: top;
  }

  .horizontal-center {
    margin: 0 auto;
  }

  .full-width {
    width: 100%;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left !important;
  }

  .div-inline {
    display: inline-block !important;
  }

  .div-block {
    display: block !important;
  }

  .div-inline-block {
    display: inline-block !important;
  }

  .div-block[hidden] {
    display: none !important;
  }

  .flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-grow {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .divider {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px 8px;
    display: inline-block;
    height: 12px;
  }

  .no-margin {
    margin: 0 !important;
  }

  .no-margin-top {
    margin-top: 0 !important;
  }

  .no-margin-right {
    margin-right: 0 !important;
  }

  .no-padding {
    padding: 0 !important;
  }

  .no-padding-left {
    padding-left: 0 !important;
  }

  .no-padding-right {
    padding-right: 0 !important;
  }

  .no-padding-top {
    padding-top: 0 !important;
  }

  .no-padding-left-right {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .no-padding-top-left {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }

  .no-padding-top-right {
    padding-top: 0 !important;
    padding-right: 0 !important;
  }

  .no-padding-bottom-left {
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }

  .no-padding-bottom-right {
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }

  .no-padding-top-bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .no-padding-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .f-16 {
    font-size: 16px;
  }

  .f-12 {
    font-size: 12px;
  }

  .f-18 {
    font-size: 18px;
  }

  .f-20 {
    font-size: 20px;
  }

  .h-full {
    height: 100% !important;
  }

  .m-h-36 {
    min-height: 36px;
  }

  .m-w-auto {
    min-width: auto !important;
  }

  .m-w-48 {
    min-width: 48px;
  }

  .m-w-60 {
    min-width: 60px;
  }

  .m-w-80 {
    min-width: 80px;
  }

  .m-w-84 {
    min-width: 84px;
  }

  .m-w-140 {
    min-width: 140px;
  }

  .m-w-160 {
    min-width: 160px;
  }

  .ma-w-280 {
    max-width: 280px;
  }

  .ma-w-360 {
    max-width: 360px;
  }

  .ma-w-480 {
    max-width: 480px;
  }

  .w-full {
    width: 100% !important;
  }

  .w-40 {
    width: 40px !important;
  }

  .w-75 {
    width: 75px !important;
  }

  .w-80 {
    width: 80px !important;
  }

  .w-85 {
    width: 85px !important;
  }

  .w-100 {
    width: 100px !important;
  }

  .w-120 {
    width: 120px !important;
  }

  .w-auto {
    width: auto !important;
  }

  .w-175 {
    width: 175%;
  }

  .w-140 {
    width: 140px;
  }

  .w-160 {
    width: 160px !important;
  }

  .w-180 {
    width: 180px;
  }

  .w-210 {
    width: 210px;
  }

  .w-240 {
    width: 240px;
  }

  .w-280 {
    width: 280px;
  }

  .w-320 {
    width: 320px;
    max-width: 320px;
  }

  .w-360 {
    width: 360px;
  }

  .w-460 {
    width: 460px !important;
  }

  .w-420 {
    width: 420px !important;
  }

  .w-480 {
    width: 480px !important;
  }

  .w-520 {
    width: 520px !important;
  }

  .w-650 {
    width: 650px !important;
  }

  .w-720 {
    width: 720px !important;
  }

  .h-24 {
    height: 24px;
  }

  .h-38 {
    height: 38px !important;
  }

  .h-100 {
    height: 100%;
  }

  .m-auto {
    margin: auto;
  }

  .m-t-4 {
    margin-top: 4px;
  }

  .m-t-8 {
    margin-top: 8px;
  }

  .m-t-16 {
    margin-top: 16px;
  }

  .m-t-12 {
    margin-top: 12px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-24 {
    margin-top: 24px;
  }

  .m-t-36 {
    margin-top: 36px;
  }

  .m-b-8 {
    margin-bottom: 8px;
  }

  .m-b-12 {
    margin-bottom: 12px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-16 {
    margin-bottom: 16px !important;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-24 {
    margin-bottom: 24px !important;
  }

  .m-b-36 {
    margin-bottom: 36px;
  }

  .m-b-45 {
    margin-bottom: 45px;
  }

  .m-b-48 {
    margin-bottom: 48px !important;
  }

  .m-b-50 {
    margin-bottom: 50px;
  }

  .m-b-74 {
    margin-bottom: 74px;
  }

  .m-b-80 {
    margin-bottom: 80px;
  }

  .m-b-86 {
    margin-bottom: 86px;
  }

  .m-b-auto {
    margin-bottom: auto;
  }

  .m-r-auto {
    margin-right: auto;
  }

  .m-r-2 {
    margin-right: 2px;
  }

  .m-r-8 {
    margin-right: 8px;
  }

  .m-r-12 {
    margin-right: 12px;
  }

  .m-r-13 {
    margin-right: 13px;
  }

  .m-r-16 {
    margin-right: 16px;
  }

  .m-r-24 {
    margin-right: 24px !important;
  }

  .m-r-28 {
    margin-right: 28px;
  }

  .m-r-36 {
    margin-right: 36px;
  }

  .m-r-72 {
    margin-right: 36px !important;
  }

  .m-l-auto {
    margin-left: auto;
  }

  .m-l-4 {
    margin-left: 4px;
  }

  .m-l-8 {
    margin-left: 8px;
  }

  .m-l-12 {
    margin-left: 12px;
  }

  .m-l-16 {
    margin-left: 16px;
  }

  .m-l-24 {
    margin-left: 24px;
  }

  .m-l-56 {
    margin-left: 56px;
  }

  .m-l-n-9 {
    margin-left: -9px;
  }

  .no-margin-bottom {
    margin-bottom: 0 !important;
  }

  .p-l-24 {
    padding-left: 24px;
  }

  .p-b-24 {
    padding-bottom: 24px;
  }

  .p-r-24 {
    padding-right: 24px;
  }

  .b-b-1 {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
  }

  .border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
  }

  .background-white {
    background: #ffffff;
  }

  .content-padding {
    padding: 24px 16px;
  }

  .panel-content-box {
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .p-col-1,
  .p-col-2,
  .p-col-3,
  .p-col-4,
  .p-col-5,
  .p-col-6,
  .p-col-7,
  .p-col-8,
  .p-col-9,
  .p-col-10,
  .p-col-11,
  .p-col-12 {
    padding: 12px;
  }

  .invalid-msg {
    color: #e42e07;
    text-align: left;
    margin-top: 8px;
    display: block;
    font-size: 12px;
  }

  .success-msg {
    color: #28a745;
    text-align: left;
    margin-top: 8px;
    display: block;
    font-size: 12px;
  }

  .invalid-msg-2 {
    border: 1px solid #e42e07;
    background-color: rgba(228, 46, 7, 0.1);
    color: rgb(63, 63, 63);
    text-align: left;
    margin-top: 8px;
    padding: 8px;
  }

  .rotate-90 {
    font-weight: 600;
    -webkit-transform: rotateZ(90deg); /* Safari */
    -ms-transform: rotate(90deg);
    transform: rotateZ(90deg);
    color: rgb(63, 63, 63);
    vertical-align: middle;
  }

  .normal {
    font-weight: normal;
  }

  .bold {
    font-weight: 600;
  }

  .not-bold {
    font-weight: 500;
  }

  .optional {
    &::before {
      content: "(Optional)";
    }

    color: $lighterGray;
    margin-left: 4px;
  }

  .mandatory {
    &::after {
      content: "*";
      color: #e42e07;
      margin-left: 4px;
    }
  }

  .error {
    &::after {
      content: "This is a mandatory field";
      color: #e42e07;
      margin-top: 4px;
      display: block;
      font-size: 12px;
    }
  }

  .p-input-container.loading {
    position: relative;

    &::after {
      content: url("../../images/ellipse.png");
      width: 14px;
      height: 14px;
      position: absolute;
      top: 10px;
      right: 12px;
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }

    .input-action,
    .clearSearch {
      display: none;
    }
  }

  .tooltip-top-yellow {
    width: 250px !important;    
    z-index: 9999 !important;
    max-width: 250px !important;

    .p-tooltip-text {
      width: 245px !important;
      border: 1px solid #f4d393 !important;
      background-color: #fef6e7 !important;
      color: rgba(0, 0, 0, 0.8) !important;
      font-size: 13px !important;
      font-weight: 400 !important;
    }

    .p-tooltip-arrow {
      margin-left: unset !important;
      height: 8px !important;
      width: 8px !important;
      border-width: unset !important;
      border-top-color: #fef6e7 !important;
    }

    .p-tooltip-arrow::before {
      margin-left: -3px !important;
      content: "";
      border-width: 11px 11px 0 !important;
      margin-top: -1px !important;
      position: absolute !important;
      border-color: #fef6e7 transparent transparent transparent !important;
      border-style: solid !important;
    }

    .p-tooltip-arrow::after {
      margin-left: -3px !important;
      border-width: 11px 11px 0;
      content: '';
      position: absolute !important;
      width: 0 !important;
      height: 0 !important;
      border-color: #f4d393 transparent transparent transparent !important;
      border-style: solid;
      z-index: -1 !important;
    }
  }

  .tooltip-bottom-yellow {
    width: 250px !important;    
    z-index: 9999 !important;
    max-width: 550px !important; 
  
    .p-tooltip-text {
      width: 545px !important;
      border: 1px solid #f4d393 !important;
      background-color: #fef6e7 !important;
      color: rgba(0, 0, 0, 0.8) !important;
      font-size: 12px !important;
      font-weight: 420 !important;
      padding: unset !important;
      padding-right: 10px !important;
      margin-top: 8px !important;
    }
  
    .p-tooltip-arrow {
      left: 15% !important;
      margin-left: -2px;
      margin-top: 3.5px !important;
      border-bottom-color: #fef6e7 !important;
    }

    .p-tooltip-arrow::before {
      margin-left: -12px !important;
      margin-top: -0.6px !important;
      border-width: 0 12px 12px 12px !important;
      content: '';
      position: absolute !important;
      width: 0 !important;
      height: 0 !important;
      border-color: transparent transparent #f4d393 transparent !important;
      border-style: solid;
      z-index: -1 !important;
    }
  }

  .tooltip-right-yellow {
    width: 250px !important;    
    z-index: 9999 !important;
    max-width: 380px !important; 
  
    .p-tooltip-text {
      width: 375px !important;
      border: 1px solid #f4d393 !important;
      background-color: #fef6e7 !important;
      color: rgba(0, 0, 0, 0.8) !important;
      font-size: 12px !important;
      font-weight: 420 !important;
      padding: 10px !important;
      margin-top: 8px !important;
    }
  
    .p-tooltip-arrow {
      left: 2.5% !important;
      margin-top: 20px !important;
      border-right-color: #fef6e7 !important;
    }

    .p-tooltip-arrow::before {
      margin-left: -13px !important;
      margin-top: -11.5px !important;
      border-width: 12px 12px 12px 12px !important;
      content: '';
      position: absolute !important;
      width: 0 !important;
      height: 0 !important;
      border-color: transparent #f4d393 transparent transparent !important;
      border-style: solid;
      z-index: -1 !important;
    }
  }

  .p-input-container:not(::content) {
    .input-action {
      display: none;
    }
  }

  .p-input-container.loaded {
    position: relative;

    .input-action {
      display: inline-flex;
      position: absolute;
      top: 10px;
      right: 8px;
    }
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}

.print-me,
.print-me-inline,
.print-me-flex {
  display: none !important;
}

@media print {
  .dont-print {
    display: none !important;
  }

  .print-me-inline {
    display: inline-block !important;
  }

  .print-me {
    display: block !important;
  }

  .print-me-flex {
    display: flex !important;
  }

  .print-bold {
    font-weight: 600;
  }

  .light-bold {
    font-weight: 500;
  }

  body .content .remove-flex-print {
    display: block !important;
  }
}

@page {
  margin: 24px;
}
