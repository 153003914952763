@import "../../../eurofins/theme";

$fontFamily: $euFontFamily;
$fontSize: $euFontSize;
$borderRadius: $euBorderRadius;
$disabledOpacity: $euDisabledOpacity;

//Header
$headerBorderWidth: 1px;
$headerBorderColor: $xtraLightestGray;
$headerBorderStyle: solid;
$headerBgColor: $euNeutral;
$headerTextColor: $darkGray;
$headerFontWeight: normal;
$headerFontSize: 18px;
$headerPadding: 24px;
$headerIconTextColor: $lightGray;

//Content
$contentBorderWidth: 1px;
$contentBorderColor: $xtraLightestGray;
$contentBorderStyle: solid;
$contentBgColor: $euNeutral;
$contentPadding: 24px;
$contentTextColor: $darkGray;
$contentIconTextColor: $lightGray;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: $xtraLightestGray;
$stateDefaultBgColor: $euNeutral;
$stateDefaultTextColor: $darkGray;

//Active State
$stateActiveBorderColor: $xtraLightestGray;
$stateActiveBgColor: $euNeutral;
$stateActiveTextColor: $darkGray;

//Highlight State
$stateHighlightBorderColor: $xtraLightestGray;
$stateHighlightBgColor: rgba(245, 167, 16, 0.1);
$stateHighlightTextColor: $darkGray;

//Focus State
$stateFocusBorderColor: $euSecondary;
$stateFocusBgColor: $euNeutral;
$stateFocusTextColor: $darkGray;

//Error State
$stateErrorBorderColor: $euError;
$stateErrorBgColor: $euNeutral;
$stateErrorTextColor: $euError;

//Hover State
$stateHoverBorderColor: $xtraLightestGray;
$stateHoverBgColor: $euNeutral;
$stateHoverTextColor: $darkGray;

//Forms
$inputBgColor: $euNeutral;
$inputTextColor: $darkGray;
$invalidInputBorderColor: $euError;
$inputGroupTextColor: $darkGray;

@import "../primeng-rules";

//Custom css
@import "utilities";
@import "../../components/p-menubar";
@import "../../components/p-dialog";
@import "../../components/p-orderlist";
@import "../../components/p-button";
@import "../../components/p-checkbox";
@import "../../components/p-accordion";
@import "../../components/p-panel";
@import "../../components/p-input";
@import "../../components/p-calendar";
@import "../../components/p-selectbutton";
@import "../../components/p-grid";
@import "../../components/p-splitbutton";
@import "../../components/p-dropdown";
@import "../../components/p-overlaypanel";
@import "../../components/p-datatable";
@import "../../components/p-radiobutton";
@import "../../components/p-treetable";
@import "../../components/p-tabview";
@import "../../components/p-progress-spinner";
@import "../../components/p-form";
@import "../../components/p-tooltip";
@import "../../components/p-header";
@import "../../components/p-footer";
@import "../../components/p-breadcrumb";
@import "../../components/p-tree";
@import "../../components/p-sidebar";
@import "../../components/p-switch";
@import "../../components/p-fileupload";
@import "../../components/p-card";
@import "../../components/p-toast";
@import "../../components/p-listbox";
@import "../../components/p-tabmenu";
@import "../../components/p-autocomplete";
@import "../../components/p-contextmenu";
@import "../../components/p-paginator";
@import "../../components/p-progressbar";
@import "../../components/p-badge";
@import "../../components/p-steps";
@import "../../components/p-multiselect";
@import "../../components/p-toogle";