body {
  textarea,
  input.p-inputtext,
  input {
    background: $euNeutral;
    border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: $borderRadius;
    padding: 10px 12px;
    font-size: $fontSize;
    width: 100%;
    min-height: 36px;

    &:hover,
    &:enabled:hover:not(.p-state-error),
    &.ng-dirty.ng-invalid {
      border-color: rgba(0, 0, 0, 0.2);

      &:-ms-input-placeholder {
        color: $lighterGray;
      }

      &::-webkit-input-placeholder {
        color: $lighterGray;
      }
    }

    &.ng-invalid.has-error,
    &.has-error {
      border-color: rgb(216, 12, 12);
      color: rgb(230, 14, 14);

      &:hover,
      &:focus,
      &:focus:not(.p-state-error) {
        border-color: rgb(216, 12, 12) !important;
        color: rgb(230, 14, 14) !important;
      }

      &:-ms-input-placeholder {
        color: $lighterGray;
      }

      &::-webkit-input-placeholder {
        color: $lighterGray;
      }
    }
    &:focus,
    &:enabled:focus:not(.p-state-error) {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
      border-color: rgba(0, 81, 229, 0.6);
      border-width: 1px;
    }

    @media print {
      display: none;
    }

    &:-ms-input-placeholder {
      font-family: $fontFamily;
      color: $lighterGray;
      font-size: $fontSize;
    }

    &::-webkit-input-placeholder {
      font-family: $fontFamily;
      color: $lighterGray;
      font-size: $fontSize;
    }
  }

  input.p-inputtext[type="text"].focus,
  input.focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border-color: rgba(0, 81, 229, 0.6);
    border-width: 1px;
  }

  input.p-inputtext[type="text"].invalid-input,
  input.invalid-input {
    border-color: $euError;
    outline: none;
    -webkit-box-shadow: 0;
    box-shadow: 0;

    &:hover,
    &:enabled:hover:not(.p-state-error) {
      border-color: $euError;
    }

    &:focus,
    &:enabled:focus:not(.p-state-error) {
      border-color: $euError;
    }
  }

  input.p-inputtext[type="text"],
  input {
    height: 36px;
  }

  input.p-inputtext[type="number"] {
    width: 80px;
    height: 36px;
  }

  input.p-inputtext.p-inputlarge {
    width: 108px;
    height: 48px;
  }

  textarea.p-inputtext,
  textarea {
    height: 72px;
    resize: none;
    padding: 10px 12px;
    border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
  }

  textarea.p-inputtext.fixed-height {
    height: 36px;
    overflow: hidden;
  }

  .p-inputgroup {
    position: relative;
    width: 480px;

    .p-inputgroup-addon:first-child {
      background: none !important;
      color: $lightGray;
      border: none;
      position: absolute;
      top: 10px;
      left: 12px;
      padding: 0;
      min-width: auto;
      font-size: 16px;
    }

    .p-inputgroup-addon:not(:first-child) {
      background: none !important;
      color: $lightGray;
      border: none;
      position: absolute;
      top: 10px;
      right: 12px;
      padding: 0;
      min-width: auto;
      font-size: 16px;
    }

    input:not(:first-child),
    .p-inputtext:not(:first-child) {
      padding: 10px 12px 10px 34px;
      border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
      border-radius: $borderRadius;
      width: 100%;
    }
  }

  .p-inputgroup.icon-right {
    .p-inputgroup-addon:first-child {
      left: auto;
      right: 12px;
    }

    input:not(:first-child),
    .p-inputtext:not(:first-child) {
      padding: 10px 34px 10px 12px;
    }
  }

  input.p-inputtext.p-inputtext-minimal,
  input.p-inputtext-minimal,
  textarea.p-inputtext.p-inputtext-minimal,
  textarea.p-inputtext-minimal {
    border-radius: 0;
    border-width: 0;
    padding: 4px 4px 4px 0;
    height: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0);

    &:focus,
    &:enabled:focus:not(.p-state-error) {
      border-width: 0;
    }
  }

  input.p-inputtext.p-inputtext-minimal,
  input.p-inputtext-minimal {
    height: 16px;
    min-height: 16px;
  }

  textarea.p-inputtext.p-inputtext-minimal,
  textarea.p-inputtext-minimal {
    height: 24px;
  }

  .p-inputtext-minimal.has-error {
    border: 1px solid #e42e07 !important;
  }

  // .p-inputgroup .p-inputtext:not(:last-child) {
  //   border-right: $contentBorderWidth $contentBorderStyle $contentBorderColor;
  // }

  .p-input-number {
    position: relative;
    input {
      min-width: 80px;
    }
    .ei.ei-caret-up,
    .ei.ei-caret-down {
      position: absolute;
      right: 12px;
      top: 9px;
      cursor: pointer;
    }
    .ei.ei-caret-down {
      top: 18px;
    }
  }

  textarea.manual-height {
    resize: both;
    height: auto;
    line-height: 14px;
  }
  @-moz-document url-prefix() {
    textarea.manual-height {
      height: 36px;
    }
  }
}
