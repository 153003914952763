body .p-tree {
  border: 0;
  border-radius: 0;
  width: auto;

  .p-tree-container {
    padding: 0;

    .p-treenode {
      padding: 0;

      .p-treenode-content {
        position: relative;
        margin-bottom: 8px;

        &:focus {
          outline: none;
        }

        .pi {
          font-family: eurofins-icon, sans-serif;
        }

        .pi-caret-right:before {
          content: "\ec8e";
        }

        .pi-caret-down:before {
          content: "\ec8f";
        }

        .p-tree-toggler {
          width: 16px;
          height: 16px;
        }

        .p-treenode-icon {
          margin-left: 1px;
        }

        .p-treenode-label {
          padding: 4px;
          margin-left: 28px;

          a {
            color: $euPrimaryLight;
            text-decoration: none;
          }
        }

        .p-tree-toggler ~ .p-treenode-label {
          margin-left: 12px;
        }

        .p-treenode-children {
          padding: 0 0 0 21px;
        }
      }

      .p-treenode-content.p-treenode-content-selected {
        background-color: #FFFFFF;
        .p-treenode-label {
          background: #EE7D11;
          color: #FFFFFF;
          border-radius: 0;
        }
      }
    }
    .p-treenode:last-child {
      .p-treenode-content {
        margin: 0;
      }
    }
  }
}
