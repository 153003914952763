.app-footer {
  display: block;
  -webkit-transition: margin 0.2s; /* For Safari 3.1 to 6.0 */
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
  @media print {
    display: none;
  }
}

// left nav changes container
// css updated for nav left
// end
.layout-nav-left .p-footer.p-footer-sticky {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: 0;
  left: 52px;
  right: 0;
  height: 36px;
  padding: 12px 24px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px -2px 4px $lightestGray;
  box-shadow: 0px -2px 4px $lightestGray;
  color: $euNeutral;
  z-index: 100;
}

.layout-nav-top .p-footer.p-footer-sticky {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 12px 24px;
  color: $euNeutral;
  z-index: 100;
  background-color: $euNeutral;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  @media print {
    display: none;
  }
}
