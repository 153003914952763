.p-orderlist {
  border: 0 $contentBorderStyle $contentBorderColor;

  .p-orderlist-controls {
    display: none;
  }

  .p-orderlist-list-container {
    width: 100%;

    .p-orderlist-caption {
      background-color: $euNeutral;
      border: none;
      text-align: left;
      font-size: 18px;
      font-weight: 600;
      color: $headerTextColor;
      padding: 16px 24px;
    }

    .p-orderlist-filter-container {
      background-color: $euNeutral;
      border: none;
      padding: 0 24px 12px 24px;

      .p-inputtext {
        height: 36px;
        width: 360px;
        background: $euNeutral;
        border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: $borderRadius;
        padding: 5px 6px 5px 2.5em;
        font-size: $fontSize;

        &:hover {
          border-color: $lighterGray;
        }

        &:focus {
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: 0;
          border-color: rgba(0, 81, 229, 0.6);
          border-width: 2px;
        }

        &::-webkit-input-placeholder {
          color: $lighterGray;
          font-size: $fontSize;
          text-align: left;
        }
      }

      .p-orderlist-filter-icon {
        top: 40%;
        left: 2.2em;
        right: auto;
        color: $lightGray;
        font-size: 16px;
      }
    }

    .p-orderlist-list {
      background-color: $euNeutral;
      border: none;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);

      &::-webkit-scrollbar {
        width: 6px;
        background-color: $euNeutral;
      }

      &::-webkit-scrollbar-thumb {
        background: $lightestGray;
      }

      .p-orderlist-item {
        padding: 16px 24px;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $lightestGray;

        .order-list-title {
          color: $contentTextColor;
          margin-bottom: 4px;
          font-weight: 600;
        }

        .order-list-info {
          color: $darkGray;
        }
      }

      .p-orderlist-item:not(.p-highlight) {
        &:hover {
          background-color: $euNeutral;
        }
      }

      .p-orderlist-item.p-highlight {
        background-color: rgba(245, 167, 16, 0.15);

        a,
        i,
        span {
          color: $darkestGray;
        }
      }

      .p-orderlist-droppoint {
        display: none;
      }
    }
  }
}
