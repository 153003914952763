body {
  .p-tooltip {
    .p-tooltip-text {
      word-break: break-word;
      padding: 8px;
      font-size: 12px;
      border-radius: 0;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .p-tooltip .p-tooltip-text {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    white-space: normal !important;
  }

  .p-tooltip.p-tooltip-right,
  .p-tooltip.p-tooltip-left {
    padding: 0 8px;
  }

  .p-tooltip-left .p-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-right: -4px;
    border-width: 8px 0 8px 8px;
    border-left-color: rgba(0, 0, 0, 0.8);
  }

  .p-tooltip-right .p-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-left: -4px;
    border-width: 8px 8px 8px 0;
    border-right-color: rgba(0, 0, 0, 0.8);
  }

  .p-tooltip-top .p-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-bottom: -4px;
    border-width: 8px 8px 0;
    border-top-color: rgba(0, 0, 0, 0.8);
  }

  .p-tooltip-bottom .p-tooltip-arrow {
    top: 0;
    left: 30%;
    margin-top: -4px;
    border-width: 0 8px 8px;
    border-bottom-color: rgba(0, 0, 0, 0.8);
  }

  .p-tooltip.p-tooltip-dop {
    max-width: 25em;

    .p-tooltip-arrow {
      border-width: 0;

      &::after {
        content: "";
        position: absolute;
        border-width: 10px;
        border-style: solid;
      }

      &::before {
        content: "";
        position: absolute;
        border-width: 10px;
        border-style: solid;
      }
    }

    .p-tooltip-text {
      padding: 8px 12px;
    }
  }

  .p-tooltip.p-tooltip-right.p-tooltip-warning {
    .p-tooltip-arrow {
      border-right-color: #f5a710;
      top: 50%;

      &::after {
        border-color: transparent #fef6e7 transparent transparent;
        left: -7px;
      }

      &::before {
        border-color: transparent #f5a710 transparent transparent;
        left: -8px;
      }
    }

    .p-tooltip-text {
      margin-left: 8px;
      background-color: #fef6e7;
      border: 1px solid #f5a710;
      color: rgb(63, 63, 63);
    }
  }

  .p-tooltip.p-tooltip-left.p-tooltip-warning {
    .p-tooltip-arrow {
      border-left-color: #f5a710;
      top: 50%;

      &::after {
        border-color: transparent transparent transparent #fef6e7;
        right: -7px;
      }

      &::before {
        border-color: transparent transparent transparent #f5a710;
        right: -8px;
      }
    }

    .p-tooltip-text {
      margin-right: 8px;
      background-color: #fef6e7;
      border: 1px solid #f5a710;
      color: rgb(63, 63, 63);
    }
  }

  .p-tooltip.p-tooltip-top.p-tooltip-warning {
    .p-tooltip-arrow {
      border-top-color: #fef6e7;
      left: 50%;
      margin-bottom: 1px;

      &::after {
        content: "";
        margin-left: -8px;
        border-width: 8px 8px 0;
        border-style: solid;
        border-color: #fef6e7 transparent transparent transparent;
        position: absolute;
        top: -8px;
      }

      &::before {
        content: "";
        margin-left: -8px;
        border-width: 8px 8px 0;
        border-style: solid;
        border-color: #f5a710 transparent transparent transparent;
        position: absolute;
        top: -7px;
      }
    }

    .p-tooltip-text {
      margin-bottom: 4px;
      background-color: #fef6e7;
      border: 1px solid #f5a710;
      color: rgb(63, 63, 63);
    }
  }

  .p-tooltip.p-tooltip-bottom.p-tooltip-warning {
    .p-tooltip-arrow {
      border-bottom-color: #f5a710;
      left: 50%;

      &::after {
        border-color: transparent transparent #fef6e7 transparent;
        top: -7px;
      }

      &::before {
        border-color: transparent transparent #f5a710 transparent;
        top: -8px;
      }
    }

    .p-tooltip-text {
      margin-top: 8px;
      background-color: #fef6e7;
      border: 1px solid #f5a710;
      color: rgb(63, 63, 63);
    }
  }

  .p-tooltip-custom {
    position: relative;
    display: inline-flex;
  }

  .p-tooltip-custom .p-tooltip-content {
    visibility: hidden;
    padding: 8px 10px;
    color: rgb(63, 63, 63);
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s;
  }

  .p-tooltip-custom.always-show .p-tooltip-content {
    visibility: visible;
    opacity: 1;
  }

  .p-tooltip-custom.p-tooltip-warning .p-tooltip-content {
    background: #fef6e7;
    border: 1px solid #f5a710;
  }

  .p-tooltip-custom .p-tooltip-content::after {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
    top: 2px;
  }

  .p-tooltip-custom .p-tooltip-content::before {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
  }

  .p-tooltip-custom:hover .p-tooltip-content {
    visibility: visible;
    opacity: 1;
  }

  .p-tooltip-custom.p-tooltip-bottom .p-tooltip-content {
    top: 150%;
    left: 50%;
    margin-left: -180px;
  }

  .p-tooltip-custom.p-tooltip-bottom .p-tooltip-content::after {
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    border-color: transparent transparent #ffffff transparent;
  }

  .p-tooltip-custom.p-tooltip-bottom.p-tooltip-warning
    .p-tooltip-content::after {
    border-color: transparent transparent #fef6e7 transparent;
  }

  .p-tooltip-custom.p-tooltip-bottom .p-tooltip-content::before {
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  }

  .p-tooltip-custom.p-tooltip-bottom.p-tooltip-warning
    .p-tooltip-content::before {
    border-color: transparent transparent #f5a710 transparent;
  }

  .p-tooltip-custom.p-tooltip-top .p-tooltip-content {
    bottom: 175%;
    left: 50%;
    margin-left: -180px;
  }

  .p-tooltip-custom.p-tooltip-top .p-tooltip-content::after {
    top: 100%;
    left: 50%;
    margin-left: -10px;
    margin-top: -2px;
    border-color: #ffffff transparent transparent transparent;
  }

  .p-tooltip-custom.p-tooltip-top.p-tooltip-warning .p-tooltip-content::after {
    border-color: #fef6e7 transparent transparent transparent;
  }

  .p-tooltip-custom.p-tooltip-top .p-tooltip-content::before {
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-color: rgba(0, 0, 0, 0.1) transparent transparent transparent;
  }

  .p-tooltip-custom.p-tooltip-top.p-tooltip-warning .p-tooltip-content::before {
    border-color: #f5a710 transparent transparent transparent;
  }

  .p-tooltip-custom.p-tooltip-left .p-tooltip-content {
    top: -8px;
    right: 120%;
  }

  .p-tooltip-custom.p-tooltip-left .p-tooltip-content::after {
    top: 24px;
    left: 100%;
    margin-top: -8px;
    border-color: transparent transparent transparent #ffffff;
  }

  .p-tooltip-custom.p-tooltip-left.p-tooltip-warning .p-tooltip-content::after {
    border-color: transparent transparent transparent #fef6e7;
  }

  .p-tooltip-custom.p-tooltip-left .p-tooltip-content::before {
    top: 24px;
    left: 100%;
    margin-top: -10px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.1);
  }

  .p-tooltip-custom.p-tooltip-left.p-tooltip-warning
    .p-tooltip-content::before {
    border-color: transparent transparent transparent #f5a710;
  }

  .p-tooltip-custom.p-tooltip-right .p-tooltip-content {
    top: -18px;
    left: 180%;
  }

  .p-tooltip-custom.p-tooltip-right .p-tooltip-content::after {
    top: 23px;
    right: 100%;
    margin-top: -8px;
    border-color: transparent #ffffff transparent transparent;
    margin-right: -2px;
  }

  .p-tooltip-custom.p-tooltip-right.p-tooltip-warning
    .p-tooltip-content::after {
    border-color: transparent #fef6e7 transparent transparent;
  }

  .p-tooltip-custom.p-tooltip-right .p-tooltip-content::before {
    top: 24px;
    right: 100%;
    margin-top: -10px;
    border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
  }

  .p-tooltip-custom.p-tooltip-right.p-tooltip-warning
    .p-tooltip-content::before {
    border-color: transparent #f5a710 transparent transparent;
  }
}

.tooltip-container {
  display: inline-block;
  position: relative;
}

.p-tooltip.tooltip-warning {
  .p-tooltip-arrow {
    border-top-color: #f5a710;
  }
  .p-tooltip-text {
    background-color: #fef6e7;
    border: 1px solid #f5a710;
    color: rgb(63, 63, 63);
  }
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: rgba(0, 0, 0, 0.8);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: rgba(0, 0, 0, 0.8);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0.8);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.8);
}
