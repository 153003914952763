body {
  .p-listbox {
    width: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;

    .p-listbox-header {
      padding: 0 24px;
      border: 0;
      border-radius: 0;
      margin: 0;

      .p-checkbox {
        display: none;
        margin: auto 12px auto auto;
        width: 14px;
        height: 14px;
        margin-right: 12px;

        @media print {
          display: none;
        }

        .p-checkbox-box {
          width: 14px;
          height: 14px;
          padding: 0 2px;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          vertical-align: top;
          background-color: $euTransparent;
          align-items: center;

          .p-checkbox-icon {
            font-size: 7px;
            font-weight: bold;
            margin: auto;
            line-height: 8px;
          }
        }

        .p-checkbox-box:not(.p-disabled):hover {
          border-color: $euSecondary;
          background-color: $euTransparent;
        }

        .p-checkbox-box.p-highlight,
        .p-checkbox-box.p-highlight.p-focus {
          border-color: $euSecondary;
          background-color: $euSecondary;
          color: $euNeutral;

          &:hover {
            border-color: $euSecondary;
            background-color: $euSecondary;
            color: $euNeutral;
          }

          .p-checkbox-icon {
            color: $euNeutral;
            line-height: 8px;
          }
        }

        .p-checkbox-box.p-highlight:not(.p-disabled) {
          &:hover {
            border-color: $euSecondary;
            background-color: $euSecondary;
            color: $euNeutral;
          }

          .p-checkbox-icon {
            color: $euNeutral;
            line-height: 8px;
          }
        }

        .p-checkbox-box.p-focus {
          border-color: $euSecondary;
          background-color: $euTransparent;
          color: $euSecondary;
          -webkit-box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
          box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;

          .p-checkbox-icon {
            color: $euNeutral;
            line-height: 8px;
          }
        }
      }

      .p-listbox-filter-container {
        background-color: $euNeutral;
        border: none;
        padding: 0;
        position: relative;
        margin: auto auto 18px auto;
        width: 100%;

        .p-inputtext {
          height: 36px;
          width: 100%;
          background: $euNeutral;
          border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: $borderRadius;
          padding: 5px 6px 5px 2.5em;
          font-size: $fontSize;

          &:hover {
            border-color: $lighterGray;
          }

          &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: 0;
            border-color: rgba(0, 81, 229, 0.6);
            border-width: 2px;
          }

          &::-webkit-input-placeholder {
            color: $lighterGray;
            font-size: $fontSize;
            text-align: left;
          }
        }

        .p-listbox-filter-icon {
          top: 10px;
          left: 12px;
          right: auto;
          color: $lightGray;
          font-size: 16px;
          margin: 0;
        }
      }
    }

    .p-listbox-list-wrapper {
      padding: 0;

      .p-listbox-list {
        .p-listbox-item {
          padding: 0 24px;
          height: 48px;
          margin: 0;
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          &:last-child {
            border-bottom: none;
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            border-color: rgba(0, 0, 0, 0.1);
            background-color: #edf4f8;

            a {
              color: #0072bc;
            }
          }

          &:focus {
            outline: none;
          }

          .p-checkbox {
            margin-right: 12px;
            width: 14px;
            height: 14px;

            @media print {
              display: none;
            }

            .p-checkbox-box {
              width: 14px;
              height: 14px;
              padding: 0 2px;
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              vertical-align: top;
              background-color: $euTransparent;

              .p-checkbox-icon {
                font-size: 7px;
                font-weight: bold;
                margin: auto;
                line-height: 8px;
              }
            }

            .p-checkbox-box:not(.p-disabled):hover {
              border-color: $euSecondary;
              background-color: $euTransparent;
            }

            .p-checkbox-box.p-highlight,
            .p-checkbox-box.p-highlight.p-focus {
              border-color: $euSecondary;
              background-color: $euSecondary;
              color: $euNeutral;

              &:hover {
                border-color: $euSecondary;
                background-color: $euSecondary;
                color: $euNeutral;
              }

              .p-checkbox-icon {
                color: $euNeutral;
                line-height: 8px;
              }
            }

            .p-checkbox-box.p-highlight:not(.p-disabled) {
              &:hover {
                border-color: $euSecondary;
                background-color: $euSecondary;
                color: $euNeutral;
              }

              .p-checkbox-icon {
                color: $euNeutral;
                line-height: 8px;
              }
            }

            .p-checkbox-box.p-focus {
              border-color: $euSecondary;
              background-color: $euTransparent;
              color: $euSecondary;
              -webkit-box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
              box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;

              .p-checkbox-icon {
                color: $euNeutral;
                line-height: 8px;
              }
            }
          }
          
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .p-checkbox ~ span {
              width: calc(100% - 26px);
            }
          }
        }
        .p-listbox-item.p-highlight {
          border: 0;
        }
      }
    }
  }
  .p-listbox-no-header.p-listbox .p-listbox-header {
    display: none;
  }
  .p-listbox-no-highlight.p-listbox .p-listbox-list-wrapper .p-listbox-list .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: $euTransparent;
  }
}
