@mixin hover-element() {
    border-color: $stateHoverBorderColor;
    background-color: $stateHoverBgColor;
    color: $stateHoverTextColor;

    a {
        color: $stateHoverTextColor;
    }
}

@mixin icon-override($icon) {
    background: none !important;
    font-family: "primeicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;

    &:before {
        content: $icon;
    }
}

.p-component {
    font-family: $fontFamily;
    font-size: $fontSize;

    input,
    select,
    textarea,
    button {
        font-family: $fontFamily;
        font-size: $fontSize;
    }

    :active {
        outline: none;
    }
}

@-moz-document url-prefix() {
    .p-component {
        font-family: arial, x-locale-body, sans-serif !important;
    }
}

.p-component-content {
    border: $contentBorderWidth solid $contentBorderColor;
    background-color: $contentBgColor;
    color: $contentTextColor;

    a {
        color: $contentTextColor;
    }
}

.p-component-header {
    border: $headerBorderWidth solid $headerBorderColor;
    background-color: $headerBgColor;
    color: $headerTextColor;
    font-weight: $headerFontWeight;

    a {
        color: $headerTextColor;
    }
}

.p-component-overlay {
    background-color: #ffffff;
    opacity: 0.1;
    filter: Alpha(Opacity=50);
}

.p-highlight {
    // border: $stateDefaultBorderWidth solid $stateDefaultBorderColor;
    background-color: $stateDefaultBgColor;
    color: $stateDefaultTextColor;

    a {
        color: $stateDefaultTextColor;
    }
}

.p-highlight {
    // border-color: $stateActiveBorderColor;
    background-color: $stateActiveBgColor;
    color: $stateActiveTextColor;

    a {
        color: $stateActiveTextColor;
    }
}

.p-highlight {
    // border-color: $stateHighlightBorderColor;
    background-color: $stateHighlightBgColor;
    color: $stateHighlightTextColor;

    a {
        color: $stateHighlightTextColor;
    }
}

.p-focus {
    border-color: $stateFocusBorderColor;
    background-color: $stateFocusBgColor;
    color: $stateFocusTextColor;

    a {
        color: $stateFocusTextColor;
    }
}

.p-state-error {
    border-color: $stateErrorBorderColor;
    background-color: $stateErrorBgColor;
    color: $stateErrorTextColor;

    a {
        color: $stateErrorTextColor;
    }
}

.p-disabled,
.p-component:disabled {
    opacity: $disabledOpacity;
    filter: Alpha(Opacity= $disabledOpacity * 100);
    background-image: none;
    cursor: default !important;

    * {
        cursor: default !important;
    }
}

/* Forms */
.p-inputtext {
    background-color: $inputBgColor;
    color: $inputTextColor;
}

.p-inputtext:enabled:hover {
    border-color: $stateHoverBorderColor;
}

.p-inputtext.p-focus,
.p-inputtext:focus {
    outline: 0 none;
    border-color: $stateFocusBorderColor;
    -moz-box-shadow: 0px 0px 5px $stateFocusBorderColor;
    -webkit-box-shadow: 0px 0px 5px $stateFocusBorderColor;
    box-shadow: 0px 0px 5px $stateFocusBorderColor;
}

.p-inputgroup {
    .p-inputgroup-addon {
        border-color: $stateDefaultBorderColor;
        background-color: lighten($stateDefaultBorderColor, 10%);
        color: $inputGroupTextColor;

        &:first-child {
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }
    }

    .p-button {
        &:first-child {
            border-top-left-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
        }
    }
}

.p-float-label input.ng-dirty.ng-invalid ~ label {
    color: $stateErrorTextColor;
}

.p-autocomplete {
    .p-autocomplete-multiple-container:not(.p-disabled) {
        &:hover {
            border-color: $stateHoverBorderColor;
        }

        &.p-focus {
            border-color: $stateFocusBorderColor;
        }
    }
}

.p-chips {
    > ul:not(.p-disabled) {
        &:hover {
            border-color: $stateHoverBorderColor;
        }

        &.p-focus {
            border-color: $stateFocusBorderColor;
        }
    }
}

.p-button:focus,
.p-button:enabled:hover,
.p-fileupload-choose:not(.p-disabled):hover {
    outline: 0 none;
    @include hover-element();
}

.p-button:enabled:active,
.p-fileupload-choose:not(.p-disabled):active {
    border-color: $stateActiveBorderColor;
    background-color: $stateActiveBgColor;
    color: $stateActiveTextColor;
}

.p-checkbox-box:not(.p-disabled):not(.p-highlight):hover {
    @include hover-element();
}

.p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    @include hover-element();
}

.p-dropdown .p-dropdown-clear-icon {
    color: lighten($inputTextColor, 40%);
}

.p-dropdown:not(.p-disabled):hover {
    @include hover-element();
}

.p-dropdown-panel .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    @include hover-element();
}

.p-listbox {
    .p-listbox-header {
        .p-listbox-filter-container {
            .fa {
                color: $inputTextColor;
            }
        }
    }

    &:not(.p-disabled) {
        .p-listbox-item:not(.p-highlight.p-disabled):hover {
            @include hover-element();
        }

        .p-listbox-item.p-disabled .p-checkbox-box:hover {
            background-color: $inputBgColor;
            border-color: $stateDefaultBorderColor;
        }
    }

    &.p-disabled {
        .p-checkbox-box:not(.p-highlight):hover {
            border-color: $stateDefaultBorderColor;
            background-color: $stateDefaultBgColor;
            color: $stateDefaultTextColor;
        }
    }
}

.p-multiselect:not(.p-disabled):hover {
    @include hover-element();
}

.p-multiselect-panel .p-multiselect-item:not(.p-highlight.p-disabled):hover {
    @include hover-element();
}

.p-multiselect-panel .p-multiselect-item.p-disabled:hover .p-checkbox-box {
    background-color: $inputBgColor;
    border-color: $stateDefaultBorderColor;
}

.p-multiselect-panel .p-multiselect-close {
    color: $headerIconTextColor;
}

.p-multiselect-panel .p-multiselect-filter-container .fa {
    color: $inputTextColor;
}

.p-spinner:not(.p-disabled) .p-spinner-button:enabled:hover {
    @include hover-element();
}

.p-spinner:not(.p-disabled) .p-spinner-button:enabled:active {
    border-color: $stateActiveBorderColor;
    background-color: $stateActiveBgColor;
    color: $stateActiveTextColor;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    @include hover-element();
}

.p-togglebutton:not(.p-disabled):not(.p-highlight):hover {
    @include hover-element();
}

.p-paginator a:not(.p-disabled):not(.p-highlight):hover {
    @include hover-element();
}

.p-paginator a {
    color: $stateDefaultTextColor;
}

.p-datatable {
    .p-rowgroup-header a {
        color: $headerTextColor;
    }

    .p-sortable-column:not(.p-highlight):hover {
        background-color: $stateHoverBgColor;
        color: $stateHoverTextColor;
    }

    .p-row-toggler {
        color: $contentTextColor;
    }

    tbody.p-datatable-hoverable-rows {
        > tr:not(.p-highlight):hover {
            cursor: pointer;
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

.p-orderlist {
    .p-orderlist-item:not(.p-highlight):hover {
        @include hover-element();
    }
}

.p-picklist {
    .p-picklist-item:not(.p-disabled):not(.p-highlight):hover {
        @include hover-element();
    }

    .p-picklist-droppoint-highlight {
        border-color: $stateHighlightBorderColor;
        background-color: $stateHighlightBgColor;
        color: darken($contentTextColor, 1%);

        a {
            color: darken($contentTextColor, 1%);
        }
    }

    .p-picklist-highlight {
        border-color: $stateHighlightBorderColor;
        color: darken($contentTextColor, 1%);

        a {
            color: darken($contentTextColor, 1%);
        }
    }
}

.p-tree {
    &.p-treenode-dragover {
        border-color: $stateHighlightBorderColor;
    }

    .p-treenode-content {
        &.p-treenode-selectable {
            .p-treenode-label:not(.p-highlight):hover {
                @include hover-element();
            }
        }

        &.p-treenode-dragover {
            background-color: $stateActiveBgColor;
            color: $stateActiveTextColor;
        }
    }

    &.p-tree-horizontal {
        .p-treenode-content.p-treenode-selectable {
            .p-treenode-label:not(.p-highlight):hover {
                background-color: inherit;
                color: inherit;
            }

            &:not(.p-highlight):hover {
                @include hover-element();
            }
        }
    }
}

.p-organizationchart {
    .p-organizationchart-node-content {
        &.p-organizationchart-selectable-node:not(.p-highlight):hover {
            @include hover-element();
        }
    }
}

.p-accordion {
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
        @include hover-element();
    }
}

.p-fieldset {
    &.p-fieldset-toggleable {
        .p-fieldset-legend:hover {
            @include hover-element();
        }
    }
}

.p-panel {
    .p-panel-titlebar {
        .p-panel-titlebar-icon:hover {
            @include hover-element();
        }
    }
}

.p-tabview {
    .p-tabview-nav {
        li {
            &:not(.p-highlight):not(.p-disabled):hover {
                @include hover-element();
            }
        }
    }
}

.p-dialog {
    .p-dialog-titlebar-icon {
        color: $headerTextColor;

        &:hover {
            @include hover-element();
        }
    }
}

.p-sidebar {
    .p-sidebar-close {
        color: $headerTextColor;

        &:hover {
            @include hover-element();
        }
    }
}

.p-overlaypanel {
    .p-overlaypanel-close:hover {
        @include hover-element();
    }
}

.p-inplace {
    .p-inplace-display:hover {
        @include hover-element();
    }
}

.p-breadcrumb {
    a {
        color: $headerTextColor;
    }
}

.p-menuitem {
    .p-menuitem-link {
        color: $contentTextColor;

        &:hover {
            @include hover-element();
            border-color: transparent;
        }
    }

    &.p-menuitem-active {
        > .p-menuitem-link {
            @include hover-element();
            border-color: transparent;
        }
    }
}

.p-tabmenu {
    .p-tabmenu-nav {
        li:not(.p-highlight):hover {
            @include hover-element();
        }
    }
}

.p-steps {
    .p-steps-item:not(.p-highlight):not(.p-disabled):hover {
        @include hover-element();
    }
}

.p-panelmenu {
    .p-panelmenu-header {
        &:not(.p-highlight):hover {
            @include hover-element();
            border-color: $stateDefaultBorderColor;

            a {
                color: $stateHoverTextColor;
            }
        }

        &.p-highlight {
            a {
                color: $stateActiveTextColor;
            }
        }
    }

    .p-panelmenu-content {
        .p-menuitem-link {
            color: $contentTextColor;

            &:hover {
                @include hover-element();
                border-color: transparent;
            }
        }
    }
}

.p-datepicker {
    .p-datepicker-header {
        a {
            color: $headerTextColor;

            &:hover {
                @include hover-element();
            }
        }
    }

    .p-datepicker-calendar {
        td:not(.p-disabled) {
            a:hover {
                @include hover-element();
            }
        }
    }

    .p-monthpicker {
        .p-monthpicker-month:hover {
            @include hover-element();
        }
    }
}

.fc {
    .fc-toolbar {
        .fc-prev-button {
            .p-icon-circle-triangle-w {
                margin-top: 0.3em;
                @include icon_override("\e900");
            }
        }

        .fc-next-button {
            .p-icon-circle-triangle-e {
                margin-top: 0.3em;
                @include icon_override("\e901");
            }
        }
    }
}

.p-rating {
    a {
        color: $inputTextColor;
    }
}

.p-organizationchart {
    .p-organizationchart-line-down {
        background-color: darken($contentBorderColor, 10%);
    }

    .p-organizationchart-line-left {
        border-right: 1px solid darken($contentBorderColor, 10%);
    }

    .p-organizationchart-line-top {
        border-top: 1px solid darken($contentBorderColor, 10%);
    }

    .p-organizationchart-node-content {
        border-color: darken($contentBorderColor, 10%);
    }

    .p-organizationchart-node-content .p-node-toggler {
        color: darken($contentBorderColor, 10%);
    }
}

/* TurboTable */
.p-datatable {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
        background-color: $headerBgColor;
        border: 1px solid $headerBorderColor;
        color: $headerTextColor;
    }

    .p-datatable-tbody > tr {
        background-color: $contentBgColor;
        color: $contentTextColor;

        > td {
            border: 1px solid $contentBorderColor;
            background-color: inherit;
        }

        &.p-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;
        }

        &.p-contextmenu-selected {
            background-color: lighten($stateHighlightBgColor, 20%);
            color: $stateHighlightTextColor;
        }
    }

    .p-sortable-column {
        .p-sortable-column-icon {
            color: $headerTextColor;
        }

        &.p-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;

            .p-sortable-column-icon {
                color: $stateHighlightTextColor;
            }
        }

        &:not(.p-highlight):hover {
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;

            .p-sortable-column-icon {
                color: $stateHoverTextColor;
            }
        }
    }

    &.p-datatable-hoverable-rows {
        .p-datatable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
            cursor: pointer;
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

@media (max-width: 35em) {
    .p-datatable-responsive .p-datatable-tbody > tr {
        border-top: 1px solid $contentBorderColor;
        border-bottom: 1px solid $contentBorderColor;
    }
}

/* Row Reorder */
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 $stateHighlightBgColor;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 $stateHighlightBgColor;
}

/* TurboTable */
.p-treetable {
    .p-treetable-thead > tr > th,
    .p-treetable-tfoot > tr > td {
        background-color: $headerBgColor;
        border: 1px solid $headerBorderColor;
        color: $headerTextColor;
    }

    .p-treetable-tbody > tr {
        background-color: $contentBgColor;
        color: $contentTextColor;

        > td {
            border: 1px solid $contentBorderColor;
            background-color: inherit;
        }

        &.p-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;

            .p-treetable-toggler {
                color: $stateHighlightTextColor;
            }
        }

        &.p-contextmenu-selected {
            background-color: lighten($stateHighlightBgColor, 20%);
            color: $stateHighlightTextColor;

            .p-treetable-toggler {
                color: $stateHighlightTextColor;
            }
        }

        .p-treetable-toggler {
            color: $contentTextColor;
        }
    }

    .p-sortable-column {
        .p-sortable-column-icon {
            color: $headerTextColor;
        }

        &.p-highlight {
            background-color: $stateHighlightBgColor;
            color: $stateHighlightTextColor;

            .p-sortable-column-icon {
                color: $stateHighlightTextColor;
            }
        }

        &:not(.p-highlight):hover {
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;

            .p-sortable-column-icon {
                color: $stateHoverTextColor;
            }
        }
    }

    &.p-treetable-hoverable-rows {
        .p-treetable-tbody > tr:not(.p-highlight):not(.p-contextmenu-selected):hover {
            cursor: pointer;
            background-color: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

@media (max-width: 35em) {
    .p-treetable-responsive .p-treetable-tbody > tr {
        border-top: 1px solid $contentBorderColor;
        border-bottom: 1px solid $contentBorderColor;
    }
}

/* Row Reorder */
.p-treetable .p-treetable-tbody > tr.p-treetable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 $stateHighlightBgColor;
}

.p-treetable .p-treetable-tbody > tr.p-treetable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 $stateHighlightBgColor;
}

/* InputSwitch */
.p-inputswitch {
    .p-inputswitch-slider {
        -webkit-transition: background-color 0.3s, box-shadow 0.2s;
        transition: background-color 0.3s, box-shadow 0.2s;
        background: rgba(0,0,0,0.1);

        &:before {
            background-color: $euNeutral;
        }
    }

    &.p-inputswitch-focus {
        .p-inputswitch-slider {
            -moz-box-shadow: 0px 0px 5px $stateFocusBorderColor;
            -webkit-box-shadow: 0px 0px 5px $stateFocusBorderColor;
            box-shadow: 0px 0px 5px $stateFocusBorderColor;
        }
    }

    &:not(.p-disabled):hover {
        .p-inputswitch-slider {
            background-color: #b7b7b7;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background-color: #186ba0;
        }

        &:not(.p-disabled):hover {
            .p-inputswitch-slider {
                background-color: darken(#186ba0, 10%);
            }
        }
    }
}

/* Validation */
.p-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext,
p-chips.ng-dirty.ng-invalid > .p-inputtext,
p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
p-checkbox.ng-dirty.ng-invalid .p-checkbox-box,
p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
p-listbox.ng-dirty.ng-invalid .p-inputtext,
p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
p-spinner.ng-dirty.ng-invalid > .p-inputtext,
p-selectbutton.ng-dirty.ng-invalid .p-button,
p-togglebutton.ng-dirty.ng-invalid .p-button {
    border-bottom-color: $invalidInputBorderColor;
}
// deprecated primeng css
/* Cornering */
// .p-corner-tl {
//     -moz-border-radius-topleft: $borderRadius;
//     -webkit-border-top-left-radius: $borderRadius;
//     border-top-left-radius: $borderRadius;
// }
// .p-corner-tr {
//     -moz-border-radius-topright: $borderRadius;
//     -webkit-border-top-right-radius: $borderRadius;
//     border-top-right-radius: $borderRadius;
// }
// .p-corner-bl {
//     -moz-border-radius-bottomleft: $borderRadius;
//     -webkit-border-bottom-left-radius: $borderRadius;
//     border-bottom-left-radius: $borderRadius;
// }
// .p-corner-br {
//     -moz-border-radius-bottomright: $borderRadius;
//     -webkit-border-bottom-right-radius: $borderRadius;
//     border-bottom-right-radius: $borderRadius;
// }
// .p-corner-top {
//     -moz-border-radius-topleft: $borderRadius;
//     -webkit-border-top-left-radius: $borderRadius;
//     border-top-left-radius: $borderRadius;
//     -moz-border-radius-topright: $borderRadius;
//     -webkit-border-top-right-radius: $borderRadius;
//     border-top-right-radius: $borderRadius;
// }
// .p-corner-bottom {
//     -moz-border-radius-bottomleft: $borderRadius;
//     -webkit-border-bottom-left-radius: $borderRadius;
//     border-bottom-left-radius: $borderRadius;
//     -moz-border-radius-bottomright: $borderRadius;
//     -webkit-border-bottom-right-radius: $borderRadius;
//     border-bottom-right-radius: $borderRadius;
// }
// .p-corner-right {
//     -moz-border-radius-topright: $borderRadius;
//     -webkit-border-top-right-radius: $borderRadius;
//     border-top-right-radius: $borderRadius;
//     -moz-border-radius-bottomright: $borderRadius;
//     -webkit-border-bottom-right-radius: $borderRadius;
//     border-bottom-right-radius: $borderRadius;
// }
// .p-corner-left {
//     -moz-border-radius-topleft: $borderRadius;
//     -webkit-border-top-left-radius: $borderRadius;
//     border-top-left-radius: $borderRadius;
//     -moz-border-radius-bottomleft: $borderRadius;
//     -webkit-border-bottom-left-radius: $borderRadius;
//     border-bottom-left-radius: $borderRadius;
// }
// .p-corner-all {
//     -moz-border-radius: $borderRadius;
//     -webkit-border-radius: $borderRadius;
//     border-radius: $borderRadius;
// }
