body .p-sidebar-in-page {
  position: relative;
  .p-sidebar-header {
    padding: 0;
    border: 0;
    margin-left: auto;
    width: 52px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 2;

    .p-sidebar-trigger {
      padding-top: 24px;
      font-size: 16px;
      cursor: pointer;
      margin: 0 auto;
      width: 16px;

      .ei-chevron-right {
        display: none;
        transition: display 1s;
      }

      .ei-chevron-left {
        display: inline-block;
        transition: display 1s;
      }
    }

    .p-sidebar-title {
      display: block;
      white-space: nowrap;
      position: absolute;
      top: 56px;
      left: 34px;
      text-transform: uppercase;
      transform-origin: 0 0;
      transform: rotate(90deg);
      color: rgba(0, 0, 0, 0.5);
      transition: display 1s;
    }
  }
  p-sidebar {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    width: 52px;
  }
  .p-sidebar {
    position: relative;
    z-index: 1 !important;
    opacity: 1 !important;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    top: 0;
    right: 0;
    width: 52px;
    min-height: 232px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: none;
    -webkit-transition: width 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: width 0.2s;
    transition: width 0.2s;
    box-sizing: border-box;

    .p-sidebar-close {
      display: none;
    }

    .p-sidebar-content {
      display: none;
      padding: 0;
    }
  }

  .p-sidebar.p-sidebar-active {
    width: 240px;

    .p-sidebar-trigger {
      right: 16px;
      left: auto;
      width: 16px;

      .ei-chevron-right {
        display: inline-block;
      }

      .ei-chevron-left {
        display: none;
      }
    }

    .p-sidebar-title {
      display: none;
    }

    .p-sidebar-content {
      display: block;
      padding: 0 32px 0 24px;

      .p-sidebar-content {
        padding: 0;
      }
    }
  }
}

body .p-sidebar-in-page.p-sidebar-active {
  .p-sidebar-trigger {
    right: 16px;
    left: auto;
    width: 16px;

    .ei-chevron-right {
      display: inline-block;
    }

    .ei-chevron-left {
      display: none;
    }
  }

  .p-sidebar-title {
    display: none;
  }

  p-sidebar {
    width: 240px;
  }

  .p-sidebar-content {
    display: block;
    padding: 0 32px 0 24px;

    .p-sidebar-content {
      padding: 0;
    }
  }
}

body p-sidebar.p-sidebar-in-page.p-sidebar-fixed {
  .p-sidebar {
    position: fixed;
  }
}

body p-sidebar.p-sidebar-in-page.p-sidebar-right {
  .p-sidebar {
    .p-sidebar-trigger {
      .ei-chevron-right {
        display: none;
      }

      .ei-chevron-left {
        display: inline-block;
      }
    }
  }

  .p-sidebar.p-sidebar-active {
    .p-sidebar-trigger {
      .ei-chevron-right {
        display: inline-block;
      }

      .ei-chevron-left {
        display: none;
      }
    }
  }
}

body p-sidebar {
  .p-sidebar {
    padding: 0;
    width: 625px;
    display: flex;
    flex-direction: column;

    .p-sidebar-header {
      margin: 0;
      font-size: 18px;
      color: $darkGray;
      border-bottom: 1px solid $xtraLightestGray;
      padding: 20px 24px;

      span {
        font-size: 18px;
      }
    }

    .p-sidebar-content {
      width: 100%;
      .p-sidebar-container {
        padding: 20px 24px;
        overflow-y: auto;
      }
    }

    .p-sidebar-close {
      position: absolute;
      cursor: pointer;
      top: 24px;
      right: 24px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      .p-sidebar-close-icon {
        font-size: 20px;
      }

      &:hover {
        background-color: $euTransparent;
      }
    }

    .p-sidebar-footer {
      padding: 16px 24px;
      position: absolute;
      width: 100%;
      bottom: 0;
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
    }
  }
}

body p-sidebar.p-sidebar-medium .p-sidebar,
body .p-sidebar-medium.p-sidebar {
  width: 720px !important;
}

body p-sidebar.p-sidebar-large .p-sidebar,
body .p-sidebar-large.p-sidebar {
  width: 920px !important;
}

body p-sidebar.p-sidebar-extra-large .p-sidebar,
body .p-sidebar-extra-large.p-sidebar {
  width: 1200px !important;
}

body > .p-sidebar {
  padding: 0 !important;
  width: auto !important;
  display: flex;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);

  .p-sidebar-header {
    margin: 0;
    font-size: 18px;
    color: $darkGray;
    border-bottom: 1px solid $xtraLightestGray;
    padding: 20px 24px;

    span {
      font-size: 18px;
    }
  }

  .p-sidebar-content {
    width: 100%;
    .p-sidebar-container {
      padding: 20px 24px;
      overflow-y: auto;
    }
  }

  .p-sidebar-close {
    position: absolute;
    cursor: pointer;
    top: 24px;
    right: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &:hover {
      background-color: $euTransparent;
    }

    .p-sidebar-close-icon {
      font-size: 20px;
    }
  }

  .p-sidebar-footer {
    padding: 16px 24px;
    position: absolute;
    width: 100%;
    bottom: 0;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    background-color: #ffffff;
  }
}

body > .p-sidebar.p-sidebar-full {
  width: 100% !important;
}
