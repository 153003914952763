body {
  .p-dialog {
    border-radius: 0;
    border: 0;
    bottom: auto;
    overflow: visible;
    background-color: $euNeutral;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    @media print {
      display: none;
    }

    .p-dialog-header {
      border-radius: 0;
      border: 0;
      padding: 20px 50px 20px 24px;
      background-color: $euNeutral;
      border-bottom: 1px solid $xtraLightestGray;
      line-height: 1.15;
      min-height: 60px;
      margin-bottom: 1px;
      position: relative;

      .p-dialog-header-icon {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 32%;
        border: 0;
        background-color: $euTransparent;
        margin: 0 24px 0 0;

        &:hover {
          border: 0;
          background-color: $euNeutral;
        }

        span {
          font-size: 20px;
        }
      }

      .p-dialog-header-maximize {
        margin: 0 60px 0 0;
        display: none;
        .p-dialog-header-maximize-icon {
          font-family: primeicons, sans-serif !important;
        }
      }

      .p-dialog-header-close {
        margin: 0 24px 0 0;

        &:focus {
          outline: none;
        }
      }

      .p-dialog-title {
        font-size: 18px;
        font-weight: 500;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        word-break: break-word;
        color: rgba(0,0,0,0.8);

        p-header {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          align-items: center;
        }
      }
    }

    .p-dialog-content {
      padding: 24px;
      border: 0;
      white-space: pre-line;
      .modal-icon {
        width: 13%;
      }
      .modal-info {
        width: 87%;
      }
    }
  }
  .p-dialog-no-header {
    .p-dialog {
      .p-dialog-header {
        padding: 0;
        height: 0;

        .p-dialog-header-close {
          margin: 16px 16px 0 0;
        }
      }

      .p-dialog-content {
        padding: 0;
        border: 0;
      }
    }
  }

  .p-dialog {
    .p-dialog-footer {
      padding: 12px 24px;
      border-top: 1px solid $xtraLightestGray;
      border-width: 1px 0 0;
      text-align: right;
      flex-shrink: 0;

      button.p-button {
        margin: 0 0 0 12px;
        padding: 10px 16px;
        width: auto;
        height: 36px;

        .p-button-text {
          padding: 0;
        }
      }
    }
  }
}

.p-dialog.p-dynamicdialog {
  .p-dialog-content {
    overflow: visible;
  }
}

p-dynamicdialog {
  .p-dialog {
    .p-dialog-content {
      padding: 0;

      .p-dynamic-dialog-header,
      p-header {
        border-radius: 0;
        border: 0;
        padding: 20px 50px 20px 24px;
        background-color: #ffffff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        line-height: 1.15;
        position: absolute;
        top: -60px;
        left: 0;
        z-index: 1;
        width: 100%;
        min-height: 60px;
        margin-bottom: 1px;
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .p-dynamic-dialog-body {
        overflow: auto;
        margin-bottom: 60px;
        max-height: calc(100vh - 240px);
        padding: 24px;
      }

      .p-dynamic-dialog-footer,
      p-footer {
        padding: 12px 24px;
        margin: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 60px;
      }
    }
    .p-dialog-header .p-dialog-header-maximize {
      margin: 0 24px 0 0;
      display: block;
    }
  }
}

.p-dialog-mask.p-component-overlay {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
}
