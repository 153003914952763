body {
  p-radiobutton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
    margin-right: 10px;

    @media print {
      display: none;
    }

    .p-radiobutton {
      width: 16px;
      height: 16px;
      margin-right: 10px;

      .p-radiobutton-box {
        width: 16px;
        height: 16px;
        line-height: 1.125em;
        border-radius: 100%;
        text-align: center;
        position: relative;
        border-width: 1px;
        border-style: solid;
        border-color: $lighterGray;
        background-color: $euTransparent;

        &:not(.p-disabled):not(.p-highlight):hover {
          border-width: 1px;
          border-color: $lighterGray;
          background-color: $euTransparent;
        }

        .p-radiobutton-icon {
          top: -1px;
          bottom: auto;
          left: -1px;
          right: auto;
          margin: 4px;
          border-radius: 4px;
        }

        .p-radiobutton-icon.pi.pi-circle-on::before {
          content: "";
        }
      }
      .p-radiobutton-box.p-highlight {
        border-color: $euSecondary;
        background-color: $euNeutral;
        color: $euNeutral;

        .p-radiobutton-icon {
          border-color: $euSecondary;
          border-style: solid;
        }
      }
      .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
        border-color: $euSecondary;
        background-color: $euNeutral;
        color: $euNeutral;
      }
      .p-radiobutton-box.p-highlight.p-focus {
        border-color: $euSecondary;
        background-color: $euNeutral;
        color: $euNeutral;
        -webkit-box-shadow: 0 0 0 0.2em rgba(238, 125, 17, 0.25);
        box-shadow: 0 0 0 0.2em rgba(238, 125, 17, 0.25);
      }
    }
    .p-radiobutton-label {
      font-size: $fontSize;
      margin: auto 0;
      color: rgba(0,0,0,0.8);
      margin-right: 10px;
    }
  }
  .p-highlight p-radiobutton {
    .p-radiobutton {
      .p-radiobutton-box {
        border-color: $euSecondary;
        background-color: $euNeutral;
        color: $euNeutral;

        .p-radiobutton-icon {
          background-color: $euSecondary;
        }
      }
      .p-radiobutton-box:not(.p-disabled):hover {
        border-color: $euSecondary;
        background-color: $euNeutral;
        color: $euNeutral;
      }
      .p-radiobutton-box.p-focus {
        border-color: $euSecondary;
        background-color: $euNeutral;
        color: $euNeutral;
        -webkit-box-shadow: 0 0 0 0.2em rgba(238, 125, 17, 0.25);
        box-shadow: 0 0 0 0.2em rgba(238, 125, 17, 0.25);
      }
    }
  }
}
