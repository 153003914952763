body {
  .p-tabview {
    padding: 0;
    border: 0;
    background: $euTransparent;
    border-radius: 3px;

    .p-tabview-panels {
      border: 0;
      padding: 12px 0 0 0;

      .p-tabview-panel {
        padding: 0;
      }
    }

    .p-tabview-nav {
      padding: 0;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: $xtraLightestGray;
      background: $euTransparent;

      > li {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $euTransparent;
        color: $lightGray;
        margin: 0 0 -2px 0;
        margin-right: 24px;
        margin-bottom: 1px;
        background: $euTransparent;
        outline: none;

        a {
          color: $lightGray;
          font-weight: 500;
          padding: 12px 8px;
          outline: none;
          .p-tabview-title {
            letter-spacing: 0.05em;
            line-height: 16px;
          }
        }

        &:focus {
          outline: none;
        }
      }

      > li:not(.p-highlight):not(.p-disabled):hover {
        border-width: 0 0 1px 0;
        border-color: $euTransparent;
        color: $lightGray;
        margin: 0 0 -2px 0;
        margin-right: 24px;
        margin-bottom: 1px;
        background: $euTransparent;

        a {
          color: $lightGray;
          font-weight: 500;
          padding: 12px 8px;
        }
      }

      > li:not(.p-highlight):not(.p-disabled):focus {
        outline: none;
      }

      > li.p-highlight {
        border-width: 0 0 3px 0;
        border-color: $euSecondary;
        color: $euSecondary;
        margin: 0 0 -2px 0;
        margin-right: 24px;
        background: $euTransparent;

        a {
          color: $euSecondary;
        }

        &:hover {
          border-width: 0 0 3px 0;
          border-color: $euSecondary;
          color: $euSecondary;
          margin: 0 0 -2px 0;
          margin-right: 24px;
          background: $euTransparent;

          a {
            color: $euSecondary;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
  .p-tabview-left.p-tabview {
    padding: 0;
    border: 0;
    background: #f4f4f6;
    height: 100%;
    display: flex;

    &::after {
      display: none;
    }

    .p-tabview-nav-content {
      height: 100%;
      overflow-y: visible;
      overflow-x: visible;
    }

    .p-tabview-panels {
      padding: 24px;
      background: $euWhite;
      float: none;
      border-width: 0 0 0 1px;
      border-radius: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.2);
      width: calc(65% - 2px);

      .p-tabview-panel {
        padding: 0;
      }
    }

    .p-tabview-nav {
      display: block;
      padding: 0 0 24px 0;
      border-width: 0;
      border-style: solid;
      border-color: $xtraLightestGray;
      background: $euTransparent;
      height: auto;
      margin: 0 0 0 -2px;
      width: 100%;
      float: none;

      > li {
        border-width: 1px 0 1px 0;
        border-radius: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0);
        color: $lightGray;
        margin: 0;
        background: $euTransparent;
        outline: none;
        width: calc(100%);

        &:first-child {
          border-width: 0 0 1px 0;
        }

        > a {
          color: $lightGray;
          font-weight: 500;
          padding: 16px;
          outline: none;
          border-width: 0 0 0 3px;
          border-radius: 0;
          border-style: solid;
          border-color: $euTransparent;
          width: 100%;
          overflow: visible;
          a {
            padding: 0;
          }
        }

        &:focus {
          outline: none;
        }
      }

      > li:not(.p-highlight):not(.p-disabled):hover {
        border-width: 1px 0 1px 0;
        border-radius: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0);
        color: $lightGray;
        margin: 0;
        background: $euTransparent;

        &:first-child {
          border-width: 0 0 1px 0;
        }

        > a {
          color: $lightGray;
          font-weight: 500;
          padding: 16px;
          border-width: 0 0 0 3px;
          border-radius: 0;
          border-color: $euTransparent;
          a {
            padding: 0;
          }
        }
      }

      > li:not(.p-highlight):not(.p-disabled):focus {
        outline: none;
      }

      > li.p-highlight {
        border-width: 1px 0 1px 0;
        border-radius: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.2);
        color: $euSecondary;
        margin: 0;
        background: $euWhite;

        &:first-child {
          border-width: 0 0 1px 0;
        }

        > a {
          color: $euSecondary;
          border-width: 0 0 0 3px;
          border-radius: 0;
          border-color: $euSecondary;
          right: -2px;
          background-color: #FFFFFF;
          a {
            padding: 0;
          }
        }

        &:hover {
          border-width: 1px 0 1px 0;
          border-radius: 0;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.2);
          margin: 0;
          background: $euWhite;

          &:first-child {
            border-width: 0 0 1px 0;
          }

          > a {
            color: $euSecondary;
            border-width: 0 0 0 3px;
            border-radius: 0;
            border-color: $euSecondary;
            a {
              padding: 0;
            }
          }
        }

        &:focus {
          outline: none;
        }
      }

      > li.p-disabled {
        background: $euTransparent;
        opacity: 1;
        a {
          color: #0072bc;
        }
        &:hover {
          a {
            color: #0072bc;
          }
        }
      }
    }
  }
}
