body {
  p-checkbox {
    display: inline-block;
    // margin: 0 8px 0 0;

    .p-checkbox {
      width: 14px;
      height: 14px;
      margin-right: 12px;

      @media print {
        display: none;
      }

      .p-checkbox-box {
        width: 14px;
        height: 14px;
        padding: 0 2px;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: top;
        background-color: $euTransparent;
        border-color: $lighterGray;
        align-items: center;
        border-width: 1px;
        border-style: solid;
        border-radius: 2px;

        .p-checkbox-icon {
          font-size: 7px;
          font-weight: bold;
          margin: auto;
          line-height: 8px;
        }
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .p-checkbox-box {
          margin-left: -1px;
        }
      }

      .p-checkbox-box:not(.p-disabled):hover {
        border-color: rgba(0, 0, 0, 0.3);
        background-color: $euTransparent;
      }

      .p-checkbox-box.p-highlight,
      .p-checkbox-box.p-highlight.p-focus {
        border-color: $euSecondary;
        background-color: $euSecondary;
        color: $euNeutral;

        &:hover {
          border-color: $euSecondary;
          background-color: $euSecondary;
          color: $euNeutral;
        }

        .p-checkbox-icon {
          color: $euNeutral;
          line-height: 8px;
        }
      }

      .p-checkbox-box.p-highlight:not(.p-disabled) {
        &:hover {
          border-color: $euSecondary;
          background-color: $euSecondary;
          color: $euNeutral;
        }

        .p-checkbox-icon {
          color: $euNeutral;
          line-height: 8px;
        }
      }

      .p-checkbox-box.p-focus {
        box-shadow: none;
      }
    }

    .p-checkbox-label {
      font-size: $fontSize;
      vertical-align: middle;
      margin-bottom: 20px;
      pointer-events: none;
    }
  }

  p-checkbox.no-checkbox-label {
    margin: 0;

    .p-checkbox {
      margin: 0;
    }
  }

  .p-checkbox {
    width: 14px;
    height: 14px;
    margin: 0;

    @media print {
      display: none;
    }

    .p-checkbox-box {
      width: 14px;
      height: 14px;
      padding: 0 2px;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      vertical-align: top;
      background-color: $euTransparent;
      border-color: $lighterGray;
      align-items: center;
      border-width: 1px;
      border-style: solid;
      border-radius: 2px;

      .p-checkbox-icon {
        font-size: 7px;
        font-weight: bold;
        margin: auto;
        line-height: 8px;
      }
    }

    .p-checkbox-box:not(.p-disabled):hover {
      border-color: rgba(0, 0, 0, 0.3);
      background-color: $euTransparent;
    }

    .p-checkbox-box.p-highlight,
    .p-checkbox-box.p-highlight.p-focus {
      border-color: $euSecondary;
      background-color: $euSecondary;
      color: $euNeutral;

      &:hover {
        border-color: $euSecondary;
        background-color: $euSecondary;
        color: $euNeutral;
      }

      .p-checkbox-icon {
        font-family: eurofins-icon, sans-serif;
        color: $euNeutral;
        line-height: 8px;
      }
    }

    .p-checkbox-box.p-highlight:not(.p-disabled) {
      &:hover {
        border-color: $euSecondary;
        background-color: $euSecondary;
        color: $euNeutral;
      }

      .p-checkbox-icon {
        color: $euNeutral;
        line-height: 8px;
      }
    }

    .p-checkbox-box.p-focus {
      box-shadow: none;
    }
  }

  /* Custom checkbox */
  .p-custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  /* Hide the browser's default checkbox */
  .p-custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    min-height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .p-custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: $euTransparent;
    border-color: $lighterGray;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    font-family: eurofins-icon, sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
  }

  /* On mouse-over, add a grey background color */
  .p-custom-checkbox:hover input ~ .checkmark {
    background-color: $euTransparent;
    border-color: rgba(0, 0, 0, 0.3);
  }

  /* When the checkbox is checked, add a blue background */
  .p-custom-checkbox input:checked ~ .checkmark {
    border-color: $euSecondary;
    background-color: $euSecondary;
    color: $euNeutral;
    display: flex;
  }

  /* Show the checkmark when checked */
  .p-custom-checkbox input:checked ~ .checkmark::before {
    margin: auto;
    display: block;
  }

  /* Style the checkmark/indicator */
  .p-custom-checkbox .checkmark::before {
    content: "\ec34";
    display: none;
  }

  .p-custom-checkbox.p-disabled {
    pointer-events: none;
    opacity: 0.35;
  }
}
