p-breadcrumb {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;

  @media print {
    display: none;
  }

  .p-breadcrumb {
    border: 0;
    border-radius: 0;
    background-color: #F4F4F6;
    -webkit-transition: padding 0.2s; /* For Safari 3.1 to 6.0 */
    -o-transition: padding 0.2s;
    transition: padding 0.2s;

    .p-breadcrumb-chevron::before {
      color: rgba(0, 0, 0, 0.4);
      content: "/";
    }

    ul {
      li {
        margin: 0 4px;
        .p-menuitem-link.p-menuitem-link-active {
          color: #0072bc;
        }
      }

      li.clickable {
        .p-menuitem-link {
          color: #0072bc;
        }
      }

      li[role="menuitem"] {
        margin: 0 4px;

        a {
          color: $euPrimaryLight;
        }

        .p-menuitem-link.p-menuitem-active {
          background-color: $euTransparent;
          color: #0072bc;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          a {
            color: $darkGray;
          }
        }
      }

      li[role="menuitem"].single {
        a {
          color: $euPrimaryLight;
        }
      }
    }
  }
}

p-breadcrumb.single {
  .p-breadcrumb {
    li[role="menuitem"] {
      a {
        color: $euPrimaryLight;
      }
    }
  }
}

.layout-nav-top {
  p-breadcrumb {
    position: relative;
    .p-breadcrumb {
      padding: 164px 0 4px 0;
    }
  }

  page-header + p-breadcrumb {
    .p-breadcrumb {
      padding: 64px 0 12px 0;
    }
  }
}

.layout-nav-left {
  p-breadcrumb {
    position: relative;
    .p-breadcrumb {
      padding: 0 0 16px 60px;
    }
  }
}

.layout-nav-left.layout-compress .app-header:hover ~ p-breadcrumb .p-breadcrumb {
  padding: 0 0 16px 240px;
}
