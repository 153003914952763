body {
  .p-selectbutton {
    @media print {
      display: none;
    }

    .p-button,
    .p-button:not(.p-disabled):not(.p-highlight):hover {
      background-color: $euNeutral;
      border-color: $euSecondary;
      border-style: solid;
      border-width: 1px;
      height: 28px;
      display: inline-flex;
      align-items: center;

      .p-button-icon-left {
        position: absolute;
        color: $lightGray;
        top: 16%;
        margin-top: 0;
        left: 8px;
        font-size: 16px;
      }

      .p-button-text {
        padding: 6px 8px 6px 32px;
        color: $darkGray;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 125%;
      }
    }
    .p-button:not(:last-child) {
      border-right: 1px !important;
    }
    .p-button.p-highlight,
    .p-button.p-highlight.p-focus,
    .p-button.p-highlight:not(.p-disabled):hover {
      background-color: $euSecondary;
      border-color: $euSecondary;

      .p-button-icon-left {
        color: $euNeutral;
        top: 16%;
        margin-top: 0;
        left: 8px;
        font-size: 16px;
      }

      .p-button-text {
        padding: 6px 8px 6px 32px;
        color: $euNeutral;
      }
    }

    .p-button {
      &:first-child {
        border-radius: $borderRadius 0 0 $borderRadius;
      }

      &:last-child {
        border-radius: 0 $borderRadius $borderRadius 0;
      }
    }
  }

  .p-selectbutton-tertiary {
    .p-selectbutton {
      .p-button,
      .p-button:not(.p-disabled):not(.p-highlight):hover {
        background-color: $euNeutral;
        border-color: #65787d;

        .p-button-icon-left {
          color: $lightGray;
        }

        .p-button-text {
          color: $darkGray;
        }
      }
      .p-button.p-highlight,
      .p-button.p-highlight.p-focus,
      .p-button.p-highlight:not(.p-disabled):hover {
        background-color: #65787d;
        border-color: #65787d;

        .p-button-icon-left {
          color: $euNeutral;
        }

        .p-button-text {
          color: $euNeutral;
        }
      }
    }
  }
}
