body .upload-file {
  width: 100%;
  position: relative;

  p-fileupload {
    display: inline-block;
    width: 100%;

    .p-fileupload-choose {
      width: 100%;
      height: 142px;
      border: 0.5px dashed rgb(103, 103, 103);
      cursor: pointer;

      .p-button-icon-left {
        font-family: eurofins-icon, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        color: $lightGray;
        left: 0;
        right: 0;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 95px;
        width: 24px;
        height: 24px;
        top: auto;
        display: flex;

        &::before {
          content: "\ea0e";
          vertical-align: sub;
        }
      }

      .p-button-text {
        padding: 0 12px;
        position: absolute;
        height: 28px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.4);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 3px;
        bottom: 24px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 68px;
        font-size: 12px;
      }

      input[type="file"] {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
  }

  p-fileupload.p-fileupload-large {
    .p-fileupload-choose {
      height: 196px;
      background-color: #f4f4f6;

      .p-button-icon-left {
        top: 28px;
        bottom: auto;

        &::before {
          content: "\e94b";
        }
      }

      .p-button-text {
        top: 96px;
        bottom: auto;
        visibility: hidden;
        position: relative;

        &:after {
          padding: 0 12px;
          position: absolute;
          height: 28px;
          display: flex;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.4);
          box-sizing: border-box;
          border-radius: 3px;
          bottom: 24px;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 68px;
          font-size: 12px;
          visibility: visible;
          top: 0;
          left: 0;
          content: "Browse";
          cursor: pointer;
        }
      }
    }
  }

  p.upload-help {
    margin: 0;
    display: inline-block;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    bottom: 62px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    text-align: center;
  }

  p-fileupload.p-fileupload-large ~ p.upload-help {
    top: 64px;
    bottom: auto;
    font-size: 13px;
  }

  p-fileupload.p-fileupload-large ~ p.supported-file-info {
    margin: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    bottom: 24px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    text-align: center;
    line-height: 150%;
    span {
      font-size: inherit;
    }
  }
}
