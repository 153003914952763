body {
  p-calendar {
    width: 100%;

    .p-calendar.p-calendar-w-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      @media print {
        display: none;
      }

      input.p-inputtext {
        background: $euNeutral;
        border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: $borderRadius;
        padding: 10px 36px 10px 12px;
        font-size: $fontSize;
        width: 100%;

        &:hover,
        &:enabled:hover:not(.p-state-error) {
          border-color: $lighterGray;
        }

        &:focus,
        &:enabled:focus:not(.p-state-error) {
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: 0;
          border-color: rgba(0, 81, 229, 0.6);
          border-width: 2px;
        }

        &::-webkit-input-placeholder {
          color: $lighterGray;
          font-size: $fontSize;
        }
      }

      .p-datepicker-trigger.p-button {
        position: absolute;
        right: 0;
        top: 0;
        background: $euTransparent;
        border-color: $euTransparent;
        color: $lightGray;
        -webkit-box-shadow: none;
        box-shadow: none;
        min-width: auto;
        padding: 0 10px 0 16px;

        &:focus {
          outline: none;
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        .p-button-label {
          display: none;
        }
      }
    }

    .p-calendar.p-calendar-timeonly {
      .p-datepicker-trigger.p-button {
        .pi.pi-calendar::before {
          font-family: eurofins-icon, sans-serif;
          content: "\ebe8";
        }
      }
      .p-datepicker {
        .p-timepicker {
          border: none;
        }
      }
    }
  }

  p-calendar.ng-dirty.ng-invalid {
    .p-calendar {
      .p-inputtext {
        border-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  p-calendar.ng-invalid.has-error {
    .p-calendar {
      .p-inputtext {
        border-color: rgb(216, 12, 12);
        color: rgb(230, 14, 14);
        border: 1px solid red !important;
      }
    }
  }

  .p-calendar {
    width: 100%;

    .p-datepicker {
      margin-top: 1px;
      background-color: #ffffff;
      padding: 12px;
      border-radius: 3px;
      border-color: rgba(0, 0, 0, 0.2);
      border-style: solid;
      border-width: 1px;
      -webkit-box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
      box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
      .p-datepicker-header {
        border: 0;
        a {
          cursor: pointer;
        }
        .p-datepicker-month,
        .p-datepicker-year {
          padding: 0 4px;
          margin-top: 0;
        }
        .p-datepicker-title {
          padding: 0 10px 0 16px;
        }
        .p-datepicker-next,
        .p-datepicker-prev {
          top: 0;
          width: 16px;
          height: 16px;
        }
      }

      .p-datepicker-calendar-container {
        .p-datepicker-calendar {
          tbody > tr > td {
            padding: 0;
            > span {
              padding: 6px 8px;
              &.p-highlight {
                border: 0;
                &:hover {
                  background-color: rgba(245, 167, 16, 0.1);
                }
              }
              &.p-disabled {
                opacity: 0.35;
                &:hover {
                  background-color: rgba(0,0,0,0);
                }
              }
              &:hover {
                background-color: #edf4f8;
              }
            }
          }
        }

        a {
          cursor: pointer;
          padding: 0.4em;
        }

        .p-datepicker-today {
          > span {
            background-color: #d2e3ed;

            &:hover {
              background-color: rgba(0, 114, 188, 0.1);
            }
          }

          > span.p-highlight {
            background-color: rgba(245, 167, 16, 0.1);
          }
        }

        .p-datepicker-current-day {
          .p-highlight {
            background-color: rgba(238, 125, 17, 0.1);
          }
        }
      }
    }
  }

  .p-datepicker {
    background-color: #ffffff;
    padding: 12px;
    border-radius: 3px;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
    box-shadow: 0 0.3em 0.3em -0.15em $lightestGray;
    .p-datepicker-header {
      border: 0;
      a {
        cursor: pointer;
      }
      .p-datepicker-month,
        .p-datepicker-year {
          padding: 0 4px;
          margin-top: 0;
        }
    }
    .p-datepicker-calendar-container {
      .p-datepicker-calendar {
        tbody > tr > td {
          padding: 0;
          > span {
            padding: 6px 8px;
            &.p-highlight {
              border: 0;
              &:hover {
                background-color: rgba(245, 167, 16, 0.1);
              }
            }
            &.p-disabled {
              opacity: 0.35;
              &:hover {
                background-color: rgba(0,0,0,0);
              }
            }
            &:hover {
              background-color: #edf4f8;
            }
          }
        }
      }

      a {
        cursor: pointer;
      }
    }
    .p-timepicker {
      border: none;
    }
  }

  p-calendar.p-calendar-minimal {
    .p-calendar.p-calendar-w-btn {
      // width: 152px;
      input.p-inputtext {
        border-radius: 0;
        border-width: 0;
        padding: 4px;
        height: 100%;
        min-height: 100%;
        min-width: 100px;
        background: rgba(0, 0, 0, 0);
        &:focus,
        &:enabled:focus:not(.p-state-error) {
          border-width: 0;
        }
      }

      .p-datepicker-trigger.p-button {
        padding: 0;
        height: 100%;
        min-height: 100%;
        bottom: 2px;
        min-width: auto;
      }
    }
  }

  p-calendar.p-calendar-minimal.has-error {
    .p-calendar.p-calendar-w-btn {
      input.p-inputtext {
        border: 1px solid #e42e07 !important;
      }
    }
  }
}
