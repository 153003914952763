body {
  p-multiselect {
    display: block;
    width: auto;

    .p-multiselect {
      background: #F4F4F6;
      border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: $borderRadius;
      font-size: $fontSize;
      width: 100%;
      height: 36px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      border-width: 1px;
      // background-image: -o-linear-gradient(
      //     45deg,
      //     transparent 50%,
      //     rgb(103, 103, 103) 50%
      //   ),
      //   -o-linear-gradient(315deg, rgb(103, 103, 103) 50%, transparent 50%),
      //   -o-linear-gradient(left, #ffffff, #ffffff);
      // background-image: linear-gradient(
      //     45deg,
      //     transparent 50%,
      //     rgb(103, 103, 103) 50%
      //   ),
      //   linear-gradient(135deg, rgb(103, 103, 103) 50%, transparent 50%),
      //   linear-gradient(to right, #ffffff, #ffffff);
      // background-position: calc(100% - 15px) calc(1em + 2px),
      //   calc(100% - 12px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
      // background-size: 4px 4px, 4px 3px, 0px 1.5em;
      background-repeat: no-repeat;
      cursor: pointer;
      min-width: 160px;

      &:not(.p-disabled):hover {
        background: #F4F4F6;
      }

      @media print {
        display: none;
      }

      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
        border-color: rgba(0, 81, 229, 0.6);
        border-width: 1px;
      }

      .p-multiselect-label {
        background: rgba(0, 0, 0, 0);
        padding: 0 40px 0 12px;
        font-size: $fontSize;
        line-height: 15px;
        text-align: left;
        max-width: 340px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.8);
      }

      .p-multiselect-clear-icon {
        font-size: 8px;
        font-weight: bolder;
        right: 32px;
        color: $lightGray;
      }

      .p-multiselect-trigger {
        width: 8px;
        height: 8px;
        top: 13px;
        right: 16px;
        border: 0;
        background-color: $euTransparent;
        margin: -2px;

        .p-multiselect-trigger-icon {
          font-size: 8px;
          font-weight: bolder;

          &.pi.pi-chevron-down::before {
            font-family: eurofins-icon,sans-serif !important;
            content: "\ecf9";
          }
        }
      }

      .p-multiselect-panel {
        border: 1px solid $lightestGray;
        border-radius: 0;
        padding: 0;
        background-color: #f4f4f6;

        // deprecated primeng css
        // .p-component-header {
        //   border-width: 0 0 1px 0;
        //   padding: 8px;
        //   margin-bottom: 0;

        //   .p-checkbox {
        //     display: none;
        //   }
        // }

        .p-multiselect-items-wrapper {
          padding: 0;
          .p-multiselect-items.p-multiselect-list {
            padding: 0;

            .p-multiselect-item {
              display: flex !important;
              align-items: center !important;
              padding: 8px;

              &:hover {
                background: #edf4f8;
              }

              .p-checkbox {
                margin: 6px;
              }
            }

            .p-multiselect-item.p-item-disabled {
              opacity: 0.35;
              pointer-events: none;
            }

            .p-multiselect-empty-message {
              color: rgb(0, 0, 0, 0.6);
              font-size: 12px;
              padding: 8px;
            }
          }
        }

        .p-multiselect-filter-container {
          margin: 0;
          padding: 0;
          width: 100%;

          .p-inputtext {
            padding: 10px 12px 10px 28px;
            border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
            border-radius: $borderRadius;
            max-width: 98%;
            

            &:hover:not(.p-state-error) {
              border-color: rgba(0, 0, 0, 0.3);
            }

            &:focus:not(.p-state-error) {
              -webkit-box-shadow: none;
              box-shadow: none;
              outline: 0;
              border-color: rgba(0, 81, 229, 0.6);
              border-width: 1px;
            }
          }

          .p-multiselect-filter-icon {
            background: none !important;
            color: $lightGray;
            border: none;
            position: absolute;
            top: 12px;
            left: 8px;
            right: auto;
            padding: 0;
            min-width: auto;
            font-size: 16px;
          }
        }

        .p-multiselect-close {
          display: none;
        }
      }
    }

    .p-multiselect.p-focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
      border-color: rgba(0, 81, 229, 0.6);
      border-width: 1px;

      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: 0;
        border-color: rgba(0, 81, 229, 0.6);
        border-width: 1px;
      }
    }
  }
  .p-multiselect-panel {
    border: 1px solid $lightestGray;
    border-radius: 0;
    padding: 0;

    // deprecated primeng css
    // .p-component-header {
    //   border-width: 0 0 1px 0;
    //   padding: 8px 28px 8px 8px;
    //   margin-bottom: 0;
    // }

    .p-multiselect-items-wrapper {
      padding: 0;
      .p-multiselect-items.p-multiselect-list {
        padding: 0;

        .p-multiselect-item {
          display: flex;
          align-items: center;
          padding: 8px;

          &:hover {
            background: #edf4f8;
          }

          .p-checkbox {
            margin: 6px;
          }
        }

        .p-multiselect-empty-message {
          color: rgb(230, 14, 14);
          padding: 8px;
        }
      }
    }

    .p-multiselect-filter-container {
      margin: 0 0 0 8px;
      padding: 0;
      width: 80%;

      .p-inputtext {
        padding: 10px 12px 10px 28px;
        border: $contentBorderWidth $contentBorderStyle $contentBorderColor;
        border-radius: $borderRadius;

        &:hover:not(.p-state-error) {
          border-color: rgba(0, 0, 0, 0.3);
        }

        &:focus:not(.p-state-error) {
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: 0;
          border-color: rgba(0, 81, 229, 0.6);
          border-width: 1px;
        }
      }

      .p-multiselect-filter-icon {
        background: none !important;
        color: $lightGray;
        border: none;
        position: absolute;
        top: 12px;
        left: 8px;
        right: auto;
        padding: 0;
        min-width: auto;
        font-size: 16px;
      }
    }
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: rgba(0, 0, 0, 0.3);
  }

  .hide-search {
    .p-multiselect {
      .p-multiselect-panel {
        .p-multiselect-filter-container {
          display: none;
        }
      }
    }
  }

  p-multiselect.p-multiselect-small {
    min-width: 75px;
    width: fit-content;

    .p-multiselect {
      min-width: 75px;
      width: fit-content;

      .p-multiselect-label {
        padding: 0 24px 0 12px;
      }

      .p-multiselect-panel {
        border: 1px solid $lightestGray;

        .p-multiselect-items-wrapper {
          overflow: visible;
          max-height: 100% !important;

          .p-multiselect-items.p-multiselect-list {
            padding: 0;

            .p-multiselect-item {
              padding: 2px 16px;
            }
          }
        }
      }
    }
  }
  p-multiselect.p-multiselect-minimal {
    .p-multiselect {
      border: none;
      height: 100%;
      background: $euTransparent;
      min-width: 52px;

      &:not(.p-disabled):hover {
        background: $euTransparent;
      }

      .p-multiselect-label {
        padding: 0 28px 0 0;
      }

      .p-multiselect-clear-icon {
        right: 16px;
      }

      .p-multiselect-trigger {
        top: 3px;
        right: 4px;

        .p-multiselect-trigger-icon {
          font-size: 8px;
          font-weight: bolder;
          margin: -5px;
        }
      }
    }
  }
  p-multiselect.has-error {
    .p-multiselect {
      border-color: rgb(216, 12, 12);
      color: rgb(230, 14, 14);

      &:hover {
        border-color: rgb(216, 12, 12);
        color: rgb(230, 14, 14);
      }
    }
  }
}
