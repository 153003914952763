.p-autoComplete {
  .p-autocomplete-loader {
    font-size: 16px;
    margin-right: 8px;
    color: #ee7d11;
  }

  input.p-inputtext {
    &:-ms-input-placeholder {
      color: $lighterGray;
    }
  }

  &:hover,
  &:enabled:hover:not(.p-state-error),
  &.ng-dirty.ng-invalid {
    input.p-inputtext {
      border-color: rgba(0, 0, 0, 0.3);

      &:-ms-input-placeholder {
        color: $lighterGray;
      }
    }
  }

  &.ng-invalid.has-error {
    input.p-inputtext {
      border-color: rgb(216, 12, 12);
      color: rgb(230, 14, 14);

      &:hover,
      &:focus,
      &:focus:not(.p-state-error) {
        border-color: rgb(216, 12, 12);
        color: rgb(230, 14, 14);
      }

      &:-ms-input-placeholder {
        color: $lighterGray;
      }
    }
  }
}
