body {
  .p-progressbar {
    height: 16px;
    border: 1px solid $contentBorderColor;
    margin-bottom: 12px;

    .p-progressbar-value {
      background-color: $euSecondary;
    }

    .p-progressbar-label {
      color: $euWhite;
      -webkit-transition: width 1s; /* For Safari 3.1 to 6.0 */
      -o-transition: width 1s;
      transition: width 1s;
      width: 0;
    }
  }
}
