body {
  p-tabmenu {
    display: block;

    .p-tabmenu {
      padding: 0;
      border: 0;
      background: $euTransparent;

      .p-tabmenu-nav {
        padding: 0;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $xtraLightestGray;
        background: $euTransparent;

        li.p-tabmenuitem {
          border-width: 0 0 1px 0;
          border-style: solid;
          border-color: $euTransparent;
          color: $lightGray;
          margin: 0;
          margin-bottom: 1px;
          background: $euTransparent;

          a {
            color: $lightGray;
            font-weight: 400;
            padding: 12px;

            &:focus {
              outline: none;
            }
          }

          &:focus {
            outline: none;
          }
        }

        li:not(.p-highlight):not(.p-disabled):hover {
          border-width: 0 0 1px 0;
          border-color: $euTransparent;
          color: $lightGray;
          margin: 0;
          margin-bottom: 1px;
          background: $euTransparent;

          a {
            color: $lightGray;
            font-weight: 400;
          }
        }

        li:not(.p-highlight):not(.p-disabled):focus {
          outline: none;
        }

        li.p-highlight {
          border-bottom: 2px;
          border-style: solid;
          border-color: $euSecondary;
          color: $euSecondary;
          margin: 0;
          background: $euTransparent;

          a {
            color: $euSecondary;
          }

          &:hover {
            border-width: 0 0 2px 0;
            border-color: $euSecondary;
            color: $euSecondary;
            margin: 0;
            background: $euTransparent;

            a {
              color: $euSecondary;
            }
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
